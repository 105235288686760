import React, { useState, useEffect } from 'react'
import DateTime from 'luxon/src/datetime.js'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader/Loader'
import styles from './ProductListitem.module.scss'
import Button from '../Button/Button'
import APIClient from '../../utils/api-client'
import { FaFolder } from 'react-icons/fa';

const ProductListitem = withRouter(({id, name, img, sku, onEdit, checklistID, history, folder}) => {

  const [lastUpdated, setLastUpdated] = useState(false);

  useEffect( () => {
    if ( checklistID ) {
      APIClient(`checklists/${checklistID}`).then( e => {
        setLastUpdated(e.data.updated_at) 
      }).catch( e => {
        setLastUpdated('N/A')
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    const onChecklistEdit = () => {
      if ( checklistID ) {
        history.push(`/checklists/edit/${checklistID}`)
      } else {
        history.push(`/checklists/create/${id}`)
      }
    }

    const getImages = () => {
      if (img.length > 1) {
        return(
          <AliceCarousel 
            mouseTrackingEnabled
            dotsDisabled
            items={ img }  
          />
        )
      } else if( img.length === 1) {
        return img[0]
      } else {
        return <img src="productPlaceholder.png" alt='TruCorp' />
      }
    }
    
  return (
    <article className={styles.productItem}>
      <div className={styles.productItemImage}>  
        <div className={styles.productitemImageContainer}>
          { getImages() }
        </div>
      </div>
      <div className={styles.productItemDescription}>
        <h3>{ name }</h3>
        { folder && (
          <p>
            <FaFolder /> {folder.name}
          </p>
        )}
        { checklistID && (
          <p>Checklist last updated: { lastUpdated ? DateTime.fromSQL(lastUpdated).toFormat('dd/LL/yyyy') : <Loader inline /> }</p>
        )}
      </div>
      <div className={styles.productItemActions}>
        <Button fullWidth onClick={onEdit} > Edit Product</Button>
        <Button fullWidth onClick={onChecklistEdit}>{ checklistID ? 'Edit Checklist' : 'Create Checklist' }</Button>
      </div>
    </article>
  )
})

export default ProductListitem