import React from 'react'
import { Select as MUISelect, MenuItem } from '@material-ui/core'

const Select = ({
  placeholder='', 
  array=undefined, 
  name=null,
  value, 
  allItemsOption=false, 
  className=null,
  fullWidth=false,
  native=false,
  onChange, 
  children
}) => { 
  
  return (
    <MUISelect
      inputProps={array !== undefined ? {arrayid:array} : {}}
      variant="outlined"
      className={className}
      fullWidth={fullWidth}
      native={native}
      name={name}
      value={value}
      displayEmpty
      labelWidth={0}
      margin="dense"
      onChange={onChange}
    >
      { (!native && allItemsOption) && (
        <MenuItem value="">All Items</MenuItem>
      )}
      { children }
    </MUISelect>
  )
}

export default Select