import React from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import APIClient from '../../../utils/api-client'
import validate from './EditStaffPasswordModalValidation';
import useForm from '../../../hooks/useForm'
import useBusy from '../../../hooks/useBusy'

const EditStaffPasswordModal = ({userID, onSubmit}) => {
  const { busy, setBusy, setNotBusy } = useBusy()

  const onStaffSubmit = (form) => {
    setBusy()
    delete values.confirm
    APIClient(`users/${userID}`, values, 'PUT').then( () => {
      onSubmit();
      setNotBusy();
    })
  }
  
  const { values, errors, handleChange, handleSubmit } = useForm(onStaffSubmit, validate);
  
  return (
    <>
      <form onSubmit={ handleSubmit } >
        <Input type="password" label="Password" name="password" errors={errors.password} value={values.password} onChange={handleChange} />
        <Input type="password" label="Confirm Password" name="confirm" errors={errors.confirm} value={values.confirm} onChange={handleChange} />
        <Button loading={busy} fullWidth type="submit" primary="true" size="large">Submit</Button>
      </form>
    </>
  )
}

export default EditStaffPasswordModal