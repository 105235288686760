import React from 'react';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'
import { buttonAlignCenter, buttonAlignRight } from './Button.module.scss'

const ConditionalWrapper = ({align, children}) => {
  if( align ) {
    if( align === 'right') {
      return (<div className={ buttonAlignRight }> { children}</div>)
    } else if( align === 'center' ) {
      return (<div className={ buttonAlignCenter }> { children}</div>)
    }
  }
  return (<>{children}</>) 
}

const Button = (props) => {

  return (
    <ConditionalWrapper align={props.align}>
      <MUIButton 
        variant="contained" 
        onClick={props.onClick} 
        disabled={props.loading === 'true' ? true : false}
        size={props.size} 
        {...props}
      >
        {props.loading === 'true' ? <CircularProgress size={24} /> : props.children}
      </MUIButton>
    </ConditionalWrapper>
  )
}

export default Button;