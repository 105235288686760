import React from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'
import { withRouter } from 'react-router-dom'
import styles from './ProductFolderListitem.module.scss'
import Button from '../Button/Button'
import FolderLink from './FolderLink'

const ProductFolderListitem = withRouter(({folder, onEdit}) => {
  return (
    <article className={styles.productItem}>
      <div className={styles.productItemDescription}>
        <h3>{folder.name}</h3>
        <FolderLink folder={folder}/>
      </div>
      <div className={styles.productItemActions}>
        <Button fullWidth onClick={onEdit} > Edit Folder</Button>
      </div>
    </article>
  )
})

export default ProductFolderListitem