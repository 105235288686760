import axios from 'axios'



const APIClient = (endpoint, body = null, type=false) => {

  // attempt to get API key
  const token = window.localStorage.getItem('TCQAUser')
  let headers = {'content-type': 'application/json'}
  
  if ( type === 'MEDIA') {
    headers = {'content-type': 'multipart/form-data'}
  }

  // if key present then add to headers
  if (token) {
    headers['X-Api-Key'] = token
  }

  let method = body ? 'POST' : 'GET';

  if (type) {
    method = type
    if ( type === 'MEDIA') method = 'POST'
  }
  
  // config object for axios request
  const config = {
    method: method,
    headers: {
      ...headers,
    },
  }

  let options

  if (method === 'GET') {
    options = {
      params: body ? body : null
    }
  } else if ( method === 'POST') {
    options = {
      data: body ? body : null
    }
  } else if ( method === 'PUT') {
    options = {
      data: body ? body : null
    }
  }  else if ( method === 'DELETE') {
    options = {
      data: body ? body : null
    }
  } else if ( method === 'MEDIA') {
    options = {
      data: body ? body : null
    }
  }

  // return the axios object
  return axios({
    ...config,
    url: process.env.REACT_APP_API_BASE + endpoint,
    ...options
  }).catch( err => {
    console.warn(err);
  })
}

export default APIClient
