import React from 'react';

import { Route } from 'react-router-dom';
import { AuthRoute } from '../../components/AuthRoute/AuthRoute'
import { main } from  './Main.module.scss';

import ProductsLayout from '../ProductsLayout/ProductsLayout';
import StaffLayout from '../StaffLayout/StaffLayout';
import HomeLayout from '../HomeLayout/HomeLayout';
import ChecklistLayout from '../ChecklistLayout/ChecklistLayout'
import ChecklistSectionLayout from '../ChecklistSectionLayout/ChecklistSectionLayout';
import ProductDatabaseLayout from '../ProductDatabaseLayout/ProductDatabaseLayout';
import ProductDatabaseDetailsLayout from '../ProductDatabaseDetailsLayout/ProductDatabaseDetailsLayout';
import CreateChecklistSectionLayout from '../CreateChecklistSectionLayout/CreateChecklistSectionLayout';
import ProductFoldersLayout from '../ProductFoldersLayout/ProductFoldersLayout';

function App() {
  return (
    <main className={main}>
      <Route exact path="/" component={HomeLayout} />
      <AuthRoute path="/staff" component={StaffLayout} />
      <AuthRoute path="/products" component={ProductsLayout} />
      <AuthRoute path="/folders" component={ProductFoldersLayout} />
      <AuthRoute path="/checklists/section/new" component={CreateChecklistSectionLayout} />
      <AuthRoute path="/checklists/section/duplicate/:sectionID" component={CreateChecklistSectionLayout} editType="DUPLICATE" />
      <AuthRoute path="/checklists/section/edit/:sectionID" component={CreateChecklistSectionLayout}  editType="EDIT"/>
      <AuthRoute exact path="/checklists" component={ChecklistSectionLayout} />
      <AuthRoute path="/checklists/create/:productID" component={ChecklistLayout} />
      <AuthRoute path="/checklists/edit/:checklistID" component={ChecklistLayout} />
      <AuthRoute exact path="/product-database" component={ProductDatabaseLayout} />
      <AuthRoute path="/product-database/details/:productID" component={ProductDatabaseDetailsLayout} />
    </main>
  );
}

export default App;
