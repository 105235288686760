import React, {useState, useEffect} from 'react'
import useForm from '../../../../../hooks/useForm'
import Button from '../../../../../components/Button/Button'
import Input from '../../../../../components/Input/Input'
import validate from './ChecklistChecklistModalValidation.js'
import CriteriaWindow from '../../../../../components/CriteriaWindow/CriteriaWindow'
import BulletPointLine from '../../../../../components/BulletPointLine/BulletPointLine'
import ModalSectionHeader from '../../../../../components/modalSectionHeader/modalSectionHeader'

const ChecklistChecklistModal = ({defaults=null, onSubmit}) => { 
  
    const BULLET_OBJECT = {main_text: '', type: 'info'}
    const CRITERIA_OBJECT = {main_text: '', acceptable: false}
  
    const [bulletCount, setBulletCount] = useState(1)
    const [criteriaCount, setCriteriaCount] = useState(1)
    const handleChecklistItemSubmit = () => {

      if (values.bullets || values.criteria) {
        let val = {
          ...values,
          bullets: Object.values(values.bullets),
          criteria: Object.values(values.criteria)
        }
        
        setValues(val)
        onSubmit(val)
      } else {
        onSubmit(values)
      }
    }    

    const handleAddNewBullet = () => {
      let newBulletCount = bulletCount + 1;
      setBulletCount(newBulletCount)

      let vals = { ...values }
      vals.bullets[bulletCount] = BULLET_OBJECT
      setValues( vals)

    }
    
    const handleAddNewCriteria = () => {
      let newCriteriaCount = criteriaCount + 1;
      setCriteriaCount(newCriteriaCount)

      let vals = { ...values }
      vals.criteria[criteriaCount] = CRITERIA_OBJECT
      setValues( vals)
    }

    const handleBulletRemoval = id => {
      let vals = { ...values }

      if( values.bullets && values.bullets[id]) {
        delete vals.bullets[id]
        setValues( vals )
      }
    }

    const handleCriteriaRemoval = id => {
      let vals = { ...values }

      if( values.criteria && values.criteria[id]) {
        delete vals.criteria[id]
        setValues( vals )
      }
    }

    const generateBullets = () => {
      let bullets = [];

      if( values.bullets ) {
        Object.keys(values.bullets).map( (key, index) => {
          bullets.push( 
            <BulletPointLine key={key} id={key} errors={(errors.bullets && errors.bullets[key]) ? errors.bullets[key] : null} values={(values.bullets && values.bullets[key]) ? values.bullets[key] : null} handleChange={handleDynamicChange} onRemove={handleBulletRemoval} />          
          )
          return true
        })
      }
      return bullets
    }

    const generateCriteria = () => {
      let criteria = [];

      if( values.criteria ) {
        Object.keys(values.criteria).map( (key, index) => {
          criteria.push( 
            <CriteriaWindow key={key} id={key} errors={(errors.criteria && errors.criteria[key]) ? errors.criteria[key] : null} values={(values.criteria && values.criteria[key]) ? values.criteria[key] : null} handleChange={handleDynamicChange} onRemove={handleCriteriaRemoval} />
          )
          return true
        })
      }
      return criteria
    }
    
    const { handleChange, handleDynamicChange, handleSubmit, values, setValues, errors, setDefaults } = useForm(handleChecklistItemSubmit, validate);
    
    useEffect( () => {

      if ( defaults ) {
        setDefaults( defaults );
      } else { 
        setDefaults( {
          main_text: '',
          bullets:  [ BULLET_OBJECT ],
          criteria: [ CRITERIA_OBJECT ],
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaults])

    return(
        <form onSubmit={ handleSubmit } >
        <ModalSectionHeader
          title="Main Instruction"
        />
        <Input autoFocus label="Main instruction" name="main_text" errors={errors.main_text} value={values.main_text} onChange={handleChange} />
        <ModalSectionHeader
          title="Bullet points for staff to check against"
          description="Useful for adding extra information or things to check against when performing a test."
        />
        { generateBullets() }
        <div className="addMoreLink">
          <span onClick={handleAddNewBullet}>Add new bullet point</span>
        </div>
        <ModalSectionHeader
          title="Acceptance Criteria Examples"
          description="Provide examples of acceptable and unacceptable products"
        />
        { generateCriteria() }
        <div className="addMoreLink">
          <span onClick={handleAddNewCriteria}>Add new criteria</span>
        </div>

        <Button align='right' type="submit" primary="true" size="large">Submit</Button>
        </form>
    )
}

export default ChecklistChecklistModal