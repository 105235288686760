
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import DateTime from 'luxon/src/datetime.js'
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable'
import APIClient from '../../utils/api-client'
import { useModal } from '../../hooks/useModal'
import FormModal from '../../components/FormModal/FormModal'
import EditProductSoNumberModal from './modals/EditProductSoNumberModal'
import SectionHeader from '../../components/SectionHeader/SectionHeader'

const ProductDatabaseLayout = withRouter(({history }) => {
  const [products, setProducts] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [args, setArgs] = useState({
    page: 1,
    limit: 10,
  });

  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()

  const handleEditSONumber = (id, val) => {
    setModalData({'id':id, 'value': val});
    toggleModal('edit')
  }

  const handleEditSONumberConfirm = () => {
    getQAData()
    toggleModal()
  }

  useEffect( () => {
    getQAData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])
  
  const tableHeaders = [
    'Product Name',
    'Serial Number',
    'QA Date',
    'SO Number',
    null
  ]

  const getQAData = () => {
    APIClient('qas', args, 'GET').then( e => {
      let formattedItems = []
      e.data.results.map( row => {
        formattedItems.push( {
          productName: row.product.name,
          serialNumber: row.serial_code,
          qaDate: DateTime.fromSQL(row.completed_at).toFormat('dd/LL/yyyy HH:mm'),
          soNumber: <><span className={'fixedWidth'}>{row.so_number || 'N/A'}</span><span className="tableLinks__inline">&nbsp;&nbsp;&nbsp;&nbsp; <span onClick={() => handleEditSONumber(row.id, row.so_number || '') }>Edit</span></span></>,
          actions: <span className="tableLinks"><Link to={`/product-database/details/${row.id}`}>View Details</Link></span> 
        })
        return true
      })
      setProducts(formattedItems) 
      setTotalCount(e.data.pagination.total_count)
    }).catch( e => {
      setProducts([])
    })
  }

  const handleArgsChange = (args) => {
    setArgs( args )
  }

  const handleProductFilter = filter => {
    setArgs({
      ...args,
      page: 1,
      product_id: filter
    })
  }
  
  return (
    <>
      <SectionHeader header='Registered Product Database' />  
      <PaginatedTable headers={tableHeaders} data={products} productFilter={handleProductFilter} stringFilter totalCount={totalCount} onArgsChange={handleArgsChange}/>

      <FormModal 
        title="Edit SO Number" 
        description='Edit the SO Number associated with this QA.'
        isActive={itemModalOpen === 'edit'} 
        handleClose={toggleModal}
      >
        <EditProductSoNumberModal productData={modalData} onSubmit={ handleEditSONumberConfirm } />
      </FormModal>
    
    </>
  );
})

export default ProductDatabaseLayout;