import React, {useState, useEffect} from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import APIClient from '../../../utils/api-client'
import validate from './EditProductSoNumberModalValidation';
import useForm from '../../../hooks/useForm'

const EditProductSoNumberModal = ({productData, onSubmit}) => {

  useEffect( () => {     
    setDefaults({
      so_number: productData['value']
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onProductSubmit = (form) => {
    setSaveProgress(true)

    const args = {
      so_number: values.so_number,
    }

    APIClient(`qas/${productData['id']}/soNumber`, args, 'PUT').then( response => {     
     
      setSaveProgress('complete')
      setTimeout( () => {
        setSaveProgress(false)
        onSubmit();
      }, 1500)
          
    }).catch( error => {
      setSaveProgress(false);
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }

  const { values, setDefaults, errors, handleChange, handleSubmit, handleServerError} = useForm(onProductSubmit, validate);

  const [saveProgress, setSaveProgress] = useState(false)

  return (
    <>

      <form onSubmit={ handleSubmit } >
        <Input label="SO Number" name="so_number" errors={errors.so_number} value={values.so_number} onChange={handleChange} />
        <Button fullWidth type="submit" color="primary" loading={saveProgress ? 'true' : 'false'} size="large">Submit</Button>
      </form>
    </>
  )
}

export default EditProductSoNumberModal