import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { useAuth } from '../../contexts/AuthContext'
import Button from '../../components/Button/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function App() {

  const { loggedIn, logout } = useAuth()

  return (
    <header className={ styles.header }>
      <div className={ styles.headerContent }>
        <img src="/logo.png" className={styles.headerLogo} alt="TruCorp"/>
          {loggedIn && (
          <ul className={styles.headerMenu}>
            <Link to="/" className={styles.headerMenuLink}>Home</Link>
            <Link to="/products" className={styles.headerMenuLink}>Products</Link>
            <Link to="/folders" className={styles.headerMenuLink}>Folders</Link>
            <Link to="/checklists" className={styles.headerMenuLink}>Test Blocks</Link>
            <Link to="/staff" className={styles.headerMenuLink}>Staff</Link>
            <Link to="/product-database" className={styles.headerMenuLink}>Registered Product Database</Link>
          </ul>
        )}
        { loggedIn && (
          <div className={ styles.userPanel}>
            <Button onClick={logout}><ExitToAppIcon className={styles.icon} /> Logout</Button>
          </div>
        )}
      </div>
    </header>
  );
}

export default App;