import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { modalTitle, modalContent, descriptionStyle } from './FormModal.module.scss';

const FormModal = ({isActive, handleClose, title, description, children, fullWidth=true, maxWidth='sm'}) => {

  return (
    <Dialog open={isActive ? true : false} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
      {title && (
        <DialogTitle className={modalTitle}>{title}</DialogTitle>
      )}
      <DialogContent className={modalContent}>
        {description && (
        <DialogContentText className={descriptionStyle}>
          { description }
        </DialogContentText>
        )}
        { children }
      </DialogContent>
    </Dialog>
  )
}

FormModal.propTypes = {
  children: PropTypes.element.isRequired,
  isActive: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default FormModal
