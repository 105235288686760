import React from 'react'
import PropTypes from 'prop-types';
import Button from '../Button/Button'
import styles from './SectionHeader.module.scss'

const SectionHeader = ({header, description, withButton=false, buttonState=null, onButtonClick}) => {

  return (
    <div className={styles.sectionHeader}>
      <h1 className={styles.sectionHeaderTitle}>{ header }</h1>
      { withButton && (
        <div className={styles.sectionHeaderActions}>
          <Button loading={buttonState === 1 ? 'true' : 'false'} fullWidth color="primary" onClick={onButtonClick}>{ buttonState === 2 ? 'Succesfully saved!' : withButton }</Button>
        </div>
      )}
      <p className={styles.sectionDescription}>{ description }</p>
    </div>
  )

}

SectionHeader.propTypes = {
  header: PropTypes.string.isRequired,
  withButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onButtonClick: function(props, propName, componentName) {    
    
    if (((props['withButton'] !== undefined && props[propName] === undefined) || (( props['withButton'] !== undefined && typeof(props[propName]) != 'function')))) {
        return new Error('You need to provide an onButtonClick function to sectionHeader');
    }
  }
};

export default SectionHeader;