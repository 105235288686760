import React, { useEffect } from 'react'
import useForm from '../../../../../hooks/useForm'
import Button from '../../../../../components/Button/Button'
import Input from '../../../../../components/Input/Input'
import validate from './ChecklistPhotoModalValidation.js'
import ModalSectionHeader from '../../../../../components/modalSectionHeader/modalSectionHeader'

const ChecklistPhotoModal = ({defaults=null, onSubmit}) => {    
    const handleChecklistItemSubmit = () => {
      onSubmit(values)
    }

    useEffect( () => {
      setDefaults( defaults );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaults])
    
    const { handleChange, handleSubmit, values, setDefaults, errors } = useForm(handleChecklistItemSubmit, validate);
    
    return(
        <form onSubmit={ handleSubmit } >
        <ModalSectionHeader
          title="Take a photo"
          description="Provide the description of the photo that the member of staff needs to document."
        />
        <Input label="Description of Photo" autoFocus name="main_text" errors={errors.main_text} value={values.main_text} onChange={handleChange} />
        <Button fullWidth type="submit" primary="true" size="large">Submit</Button>
        </form>
    )
}

export default ChecklistPhotoModal