import React from 'react'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#2f5e7b'
const secondary = '#2f5e7b'

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primary,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: secondary,
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },
});


const Theme = (props) => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>

export default Theme