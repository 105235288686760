import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import APIClient from '../../utils/api-client'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import { useModal } from '../../hooks/useModal'
import FormModal from '../../components/FormModal/FormModal'
import Input from '../../components/Input/Input'
import NewProductsModal from './modals/NewProductModal'
import EditProductsModal from './modals/EditProductModal'
import ProductFolderSelector from '../../components/ProductFolderSelector/ProductFolderSelector';
import Loader from '../../components/Loader/Loader'
import ProductListitem from '../../components/ProductListItem/ProductListItem';
import ProductsPagination from '../../components/ProductsPagination/ProductsPagination'
import styles from './ProductsLayout.module.scss'
import { Button } from '@material-ui/core';

const ProductsLayout = () => {
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [args, setArgs] = useState({
    page: 1,
    limit: 10, 
    folder_id: null,
    name_filter: null,
  });

  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()

  useEffect( () => {
    getProductData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  
  args.folder_id = searchParams.get('folder_id');
  args.name_filter = searchParams.get('name_filter');

  const [searchText, setSearchText] = useState(args.name_filter);

  const getProductData = () => {
    APIClient('products', args, 'GET').then( e => {
      setLoading(false)
      setProducts(e.data.results) 
      setTotalCount(e.data.pagination.total_count)
    })
  }

  const handleArgsChange = (args) => {
    setArgs(args)
  }

  const handleNewProductSubmit = () => {
    toggleModal()
    getProductData()
  }

  function handleFolderChange(folderId) {
    args.folder_id = folderId;
    args.name_filter = null;
    args.page = 1;

    history.replace(`?folder_id=${folderId}`)

    handleArgsChange(args)
    getProductData()
  }

  const handleEditProductSubmit = () => {
    toggleModal()
    getProductData()
  }

  const handleEditProduct = e => {
    setModalData(e);
    toggleModal('edit')
  }

  const handleNameSearch = e => {
    setSearchText(e.target.value)
  }

  const onSelectSearch = e => {
    args.folder_id = null;
    args.name_filter = searchText;

    history.replace(`?name_filter=${searchText}`)

    handleArgsChange(args);
    getProductData();
  }


  const displayProductData = () => {
    if( loading ) {
        return <Loader page />
    } else {
      if( products.length > 0 ) {
        let displayProducts = [];
        products.forEach( (product) => {
          const img = product.media.map( image => {
            return <img src={image.src} alt={product.name} />
          })
          displayProducts.push(<ProductListitem key={product.id} id={product.id} name={product.name} img={img || null} checklistID={product.checklist_id} folder={product.folder} onEdit={() => handleEditProduct(product.id)}></ProductListitem>)
        })
        displayProducts.push(<ProductsPagination key='pagination' totalCount={totalCount} onArgsChange={handleArgsChange} />) 
        return displayProducts
      } else {
        return <h4>No Products Found</h4>
      }
    }
  }
    
  return (
    <>
      <SectionHeader 
        header='Products'
        description='Manage products and their associated Quality Assurance tests. These are viewable on the app once they are added.'
        withButton="Add new" 
        onButtonClick={() => toggleModal('add')} 
      /> 
    
      <div className={styles.filterRow} >
      <ProductFolderSelector labelTitle="Filter by Folder" noneTitle="All Folders" value={args.folder_id} onFolderIdChanged={ (handleFolderChange) }/>
      <div className={styles.filterRow} >
        <Input label="Search Product Name" className={styles.filterRowSearch} onChange={handleNameSearch} value={searchText} />
        <Button type="button" onClick={onSelectSearch}>Search</Button>
      </div>
      </div>
      
    
      
      { displayProductData() }

      <FormModal 
        title="Add New Product" 
        description='Add a new product. Once this has been created you can attach a Quality Assurance Checklist to the product.'
        isActive={itemModalOpen === 'add'} 
        handleClose={toggleModal}
      >
        <NewProductsModal onSubmit={ handleNewProductSubmit } />
      </FormModal>
      <FormModal title="Edit Product" isActive={ itemModalOpen === 'edit'} handleClose={toggleModal}>
        <EditProductsModal productID={modalData} onSubmit={ handleEditProductSubmit } />
      </FormModal>
    </>
  );
}

export default ProductsLayout;