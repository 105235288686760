import React from 'react'
import Input from '../Input/Input'
import ImageUpload from '../ImageUpload/ImageUpload'
import DeleteIcon from '@material-ui/icons/Delete';
import Toggle from '../Toggle/Toggle'
import styles from './CriteriaWindow.module.scss'

const CriteriaWindow = ({id, values, errors, handleChange, onRemove}) => {

  const handleRemoval = () => {
    onRemove(id)
  }

  const getMedia = () => {
    if (values.media) return values.media
    if (values.media_id) return values.media_id
    return null
  }

  return (
    <div className={styles.criteriaWindow}>
      {
        <ImageUpload array={id} defaultImg={getMedia()} name={`criteria.media_id`} className={styles.criteriaWindowImage} onUpdate={handleChange} />
      }
      
      <div className={styles.criteriaWindowContent}>
        <div className={styles.critMain}>
          <div className={styles.critText}>
            <Input label="Criteria text" array={id} name={`criteria.main_text`} errors={ errors && errors.main_text } value={values && values.main_text ? values.main_text : ''} onChange={handleChange} />
          </div>
          <div className={styles.critRemove}>
            <DeleteIcon onClick={handleRemoval} />
          </div>
        </div>
        <Toggle name={`criteria.acceptable`} id={id} options={ ['Unacceptable', 'Acceptable'] } value={values && values.acceptable ? values.acceptable : false} onChange={handleChange} />
      </div>
    </div>
  )
}

export default CriteriaWindow