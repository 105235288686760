import React, {useState, useEffect} from 'react'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { wrap } from './ProductsPagination.module.scss'
const ProductsPagination = ({totalCount, onArgsChange}) => {
  
  const [args, setArgs] = useState({
    page: 1,
    limit: 10
  })

  const handleGetPrevPage = () => {
    setArgs({
      ...args,
      page: args.page > 1 ? args.page-1 : 1
    })
  }

  const handleGetNextPage = () => {    
    if (  args.page < (Math.ceil(totalCount/args.limit)) ) {
      setArgs({
        ...args,
        page: args.page + 1
      })
    }
  }

  useEffect( () => {
    onArgsChange(args)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  return (
    <div className={wrap}>
      <Button disabled={args.page === 1} onClick={handleGetPrevPage}>
        <NavigateBefore />    
      </Button>

      <Button disabled={args.page === (Math.ceil(totalCount/args.limit))} onClick={handleGetNextPage}>
        <NavigateNext />    
      </Button>    
    </div>
  )
}

export default ProductsPagination