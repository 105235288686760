export default function validate(values) {
  let errors = {};

  if (!values.so_number) {
    errors.so_number = 'SO Number is required';
  }
  
  if (values.so_number && values.so_number.length > 255) {
    errors.sku = 'A product SKU is required';
  } 
  
  return errors;
};