import React from 'react';
import { useAuth } from '../../contexts/AuthContext'
import LoginForm from '../LoginForm/LoginForm'
import StatsLayout from '../StatsLayout/StatsLayout'

const HomeLayout = () => {
  const { loggedIn } = useAuth()
  return (
    <>
      { !loggedIn ? (
        <LoginForm />
      ) : (
        <StatsLayout />
      )}
    </>
  );
}

export default HomeLayout;