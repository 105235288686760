import React from 'react'
import { modalSectionHeaderStyle, titleStyle, descriptionStyle } from './modalSectionHeader.module.scss';

const modalSectionHeader = ({title, description}) => 

<div className={modalSectionHeaderStyle}>
    <h3 className={titleStyle}>{title}</h3>
    <p className={descriptionStyle}>{description}</p>
</div>

export default modalSectionHeader