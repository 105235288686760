import React from 'react';
import { Link } from 'react-router-dom';

function FolderLink({ folder }) {
  return (
    <>
      {folder.product_count > 0 ? (
        <Link to={`/products?folder_id=${folder.id}`}>
          <label>{folder.product_count === 1 ? '1 Product' : `${folder.product_count} Products`}</label>
        </Link>
      ) : (
        <label>No Products</label>
      )}
    </>
  );
}

export default FolderLink;