import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import ImageMultiUpload from '../../../components/ImageMultiUpload/ImageMultiUpload'
import ApiClient from '../../../utils/api-client'
import validate from './NewProductModalValidation';
import useForm from '../../../hooks/useForm'
import Loader from '../../../components/Loader/Loader'
import ModalSectionHeader from '../../../components/modalSectionHeader/modalSectionHeader'
import ProductFolderSelector from '../../../components/ProductFolderSelector/ProductFolderSelector'

const NewProductModal = ({onSubmit}) => {

  const [files, setFiles] = useState([])
  const [saveProgress, setSaveProgress] = useState(null)
  
  const onProductSubmit = (form) => {
    setSaveProgress('Saving product')

    ApiClient('products', values).then( response => {     
    
      if( !response.data.id ) return
      
      if( files.length > 0 ) {
        handleFileUploads(response.data.id);
      } else {
        setSaveProgress('complete')
        setTimeout( () => {
          setSaveProgress(null)
          onSubmit()
        }, 1500)
      }      
    }).catch( error => {
      setSaveProgress(null);
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }

  const handleFileUploads = (id) => {
    setSaveProgress('Uploading Images')
    let promises = [];
    files.map ( (file, i) => {

      let FD = new FormData()
      FD.append('file', file)

      let F = [ ...files ]
      F[i] = {file: F[i].file, uploadState: 1}
      setFiles( F )
      promises.push( ApiClient(`products/${id}/media`, FD, 'MEDIA') )
      return true
    })

    Promise.all( promises ).then( val => {
      setSaveProgress('complete')
      setTimeout( () => {
        setSaveProgress(null)
        onSubmit()
      }, 1500)
    }).catch( vals => {
      setSaveProgress('error')
      setTimeout( () => {
        setSaveProgress(null)
        onSubmit()
      }, 1500)
    })
  }
  
  const { values, errors, handleChange, handleSubmit, handleServerError } = useForm(onProductSubmit, validate);
  
  const handleFileSelection = (e) => {

    let file
    if ( e.dataTransfer ) {
      file = e.dataTransfer.files[0]
    } else {
      file = e.target.files[0]
    }

    setFiles( [
      ...files,
      file
    ])
  }

  return (
    <>
    <ModalSectionHeader
      title="Product details"
    />
    { saveProgress && (
      <Loader page title={saveProgress} />
    )}

      <form onSubmit={ handleSubmit } >
        <Input label="Product Name" name="name" errors={errors.name } value={values.name} onChange={handleChange} />
        <Input label="Product SKU" name="sku" errors={errors.sku} value={values.sku} onChange={handleChange} />
        <ProductFolderSelector noneTitle="No folder" value={values.folder_id} onChange={ (handleChange) }/>
        <Input label="Distinguishing feature (optional)" name="distinguishing_feature" errors={errors.distinguishing_feature} value={values.distinguishing_feature} onChange={handleChange} />
        <ModalSectionHeader
          title="Product Images"
          description="Add images of the product so staff can easily identify the product when using the app."
        />
        <ImageMultiUpload name="images" onFileChange={handleFileSelection} />
        
        <Button fullWidth align='right' type="submit" color="primary" size="large">Submit</Button>
      </form>
    </>
  )
}

export default NewProductModal