import React, { useState, useEffect, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ChecklistDropzone from '../../components/ChecklistDropzone/ChecklistDropzone'
import APIClient from '../../utils/api-client'
import DateTime from 'luxon/src/datetime'
import Loader from '../../components/Loader/Loader'
import CardPool from '../../components/CardPool/CardPool'

import styles from './ChecklistLayout.module.scss'

const ChecklistLayout = ({match}) => {

  const [saveState, setSaveState] = useState(0)
  const [product, setProduct] = useState(null) 
  const [availableSections, setAvailableSections] = useState(null)
  const [checklistItems, setChecklistItems] = useState([])
  const [lastUpdated, setLastUpdated] = useState(null)

  useEffect( () => {
    if ( match.params.checklistID ) {
      APIClient(`checklists/${match.params.checklistID}`).then( e => {
        setProduct(e.data.product) 
        setLastUpdated(e.data.updated_at)
        const cut = e.data.checklist_sections.filter( sec => {
          if (sec.id === 1) {
            return false
          } else {
            return true
          }
        })

        setChecklistItems( cut )
      }).catch( e => {
      // error
      })
    } else if ( match.params.productID ) {
      APIClient(`products/${match.params.productID}`).then( e => {
        setProduct(e.data) 
      }).catch( e => {
      // error
      })
    }
  }, [match])

  useEffect( () => {
    let args = { limit: 150 }
    APIClient('checklists/sections', args, 'GET').then( e => {
      setAvailableSections(e.data.results)
    }).catch( e => {
     // error
    })
  }, [])
    
  const handleSaveSectionClick = () => {

    let checklistIDs = []
    setSaveState(1)
    checklistItems.map( item => {
      checklistIDs.push( item.id)
      return true
    })

    let args = {
      product_id: product.id ,
      checklist_sections: checklistIDs
    }

    if (match.params.productID) {
      APIClient(`checklists`, args).then( e => {
         setProduct(e.data.product) 
         setChecklistItems(e.data.checklist_sections)

         setSaveState(2)
         setTimeout( () => {
           setSaveState(0)
         }, 1500)
       }).catch( e => {
        // error
       })
    } else if ( match.params.checklistID ) {
      APIClient(`checklists/${match.params.checklistID}`, args, 'PUT').then( e => {
        setProduct(e.data.product) 
        setChecklistItems(e.data.checklist_sections)

        setSaveState(2)
        setTimeout( () => {
          setSaveState(0)
        }, 1500)
      }).catch( e => {
       // error
      })
    }
  }

  const handleNewSectionItem = item => {
    delete item.type
    setChecklistItems([ 
      ...checklistItems,
      item
    ])
  }

  const handleItemRemoval = (index) => {
    let items = checklistItems
    items.splice(index, 1);
    setChecklistItems([ ...items])
  }

  const handleReorderCards = useCallback(
    (dragIndex, hoverIndex) => {  
      let reordered = [ ...checklistItems ];
      const dragCard = checklistItems[dragIndex]
      reordered.splice(dragIndex, 1);
      reordered.splice(hoverIndex, 0, dragCard);
      setChecklistItems( [...reordered])
    }, [checklistItems]
  )
  return (
    <>
      <SectionHeader 
        header={match.params.checklistID ? 'Edit Checklist' : 'Create New Checklist'} 
        description={match.params.checklistID ? <>Build the Quality Assurance checklist using the resusable test blocks. If you need to add another test block click <a href="/checklists/section/new">here</a>. Editing this checklist will only affect future tests. Previous tests will not be affected.</> : 'Create a new Quality Assurance test for the product below. Do this by adding test blocks from the box on the right hand side.'}
        withButton="Save" 
        buttonState={saveState}
        onButtonClick={ handleSaveSectionClick } 

      />  
  
      <DndProvider backend={HTML5Backend}>
        <div className='content-sidebar'>
          <div className='content-sidebar--content'>
            <article className={styles.productItem}>
              <div className={styles.productItemImage}>
                <div className={styles.productitemImageContainer}>
                  { product ? (
                    <img src={product.media[0] ? product.media[0].src : '/logo192.png'} alt={product.name} />
                  ) : ( 
                    <Loader circular size={90} />
                  )}
                </div>
              </div>
              <div className={styles.productItemDescription}>
                <h3>{ product ? product.name : <Loader />}</h3>
                { lastUpdated && (
                  <p>Checklist last updated: { lastUpdated ? DateTime.fromSQL(lastUpdated).toFormat('dd LLL yyyy HH:mm') : <Loader inline /> }</p>
                )}
              </div>
            </article>
            <article className={styles.dropzoneContainer}>
              <h3>Checklist Tests</h3>
              <p>Create a Quality Assurance checklist test below by dragging the reusable test blocks from the box on the right hand side into the dropzone below.</p>
              <ChecklistDropzone sectionItems={checklistItems} onReorderCards={handleReorderCards} addItemToSection={ handleNewSectionItem } onRemove={handleItemRemoval} type="checklist" /> 
            </article>
          </div>
          <div className='content-sidebar--sidebar'>
            <CardPool type='section' available={availableSections} />
          </div>
        </div>
      </DndProvider>
    </>
  )
}

export default ChecklistLayout
