import React from 'react'
import { CircularProgress } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { loader, loaderTitle, inlineLoader, fullSizeLoaderRing, largerLoader } from './Loader.module.scss'
import { Dialog } from '@material-ui/core';
import classnames from 'classnames'

const Loader = ({ inline = false, title=null, circular = false, status='loading', large=false, page=false, size=40}) => {
  
  const getIcon = () => {
    switch(status) {
      case 'loading':
        return <CircularProgress size={140} className={fullSizeLoaderRing} />
      case 'complete':
        return <CheckCircleIcon size={140} className={fullSizeLoaderRing} />
      case 'error':
        return <ErrorIcon size={140} className={fullSizeLoaderRing} />
      default:
        return <CircularProgress size={140} className={fullSizeLoaderRing} />
      }
  }


  if ( circular ) {
    return <CircularProgress style={{height: size, width: size}} />
  } else if (page) {   
  
    return (
      <Dialog open={true} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
        { getIcon() }
        { title && (<p className={loaderTitle}>{title}</p>)}
      </Dialog>
    )
  } else {    
    return <span className={classnames(inline ? inlineLoader : loader, large && largerLoader)}></span>
  }
}

export default Loader