import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Warning, CheckBoxOutlined, PhotoCamera, DragIndicator } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ChecklistSectionCard.module.scss'

const ChecklistSectionCard = ({ id, isDragging, placed=false, adminTitle, title, index=9999, items, moveCard, onDelete=()=>{}}) => {
  
  const ref = useRef(null)
  
  const [, drop] = useDrop({
    accept: ['section'],
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
    
      const dragIndex = item.index
      const hoverIndex = index
      
      if (dragIndex === hoverIndex) {
        return
      }

      if(!item.variant) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ opacity }, drag] = useDrag({
    item: { id: id, admin_name: adminTitle, display_name: title, items: items, type: 'section', variant: placed, index: index },   // { type: 'section', variant: placed, index: index},
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        // when dropped
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  const getSectionItems = () => {
    if( !placed ) {
      return `${items.length} questions`
    }

    let sectionItems = []

    items.map( (element, i) => {
      let icon
      
      switch (element.type) {
        case 'prompt':
          icon = <Warning />
          break;
        case 'photo':
          icon = <PhotoCamera />
          break;
        case 'checklist_item':
          icon = <CheckBoxOutlined />
          break;
        default:
          icon = <Warning />
          break;
      }

      sectionItems.push(
        <div key={i} className={styles.checklistCardQuestionRow}>{ icon }{element.main_text || element.type}</div>
      )
      return true
    })

    return sectionItems
  }

  drag(drop(ref))

  return (
    <div ref={ref} style={{opacity}} className={styles.checklistCard}>
      {placed && (<span className={styles.closeBtn} onClick={onDelete}><CloseIcon /></span>)}
      <p className={styles.sectiontitle}>{ adminTitle }</p>
      { getSectionItems() }
      <DragIndicator className={styles.dragIcon} htmlColor="#efefef"/>
    </div>
  )
}


export default ChecklistSectionCard
