import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import styles from './ChecklistDropzone.module.scss'
import PhotoCamera from "@material-ui/icons/PhotoCamera"

import ChecklistItemCard from '../ChecklistItemCard/ChecklistItemCard'
import ChecklistSectionCard from '../ChecklistSectionCard/ChecklistSectionCard'

const ChecklistDropzone = ({sectionItems, type, onCardAdded, onReorderCards, addItemToSection, onEditCard=()=>{}, onRemove}) => {

  let acceptedItems = []

  switch(type) {
    case 'section':
      acceptedItems = ['prompt', 'checklist_item', 'photo']
      break;
    case 'checklist':
      acceptedItems = ['section']
      break;
    default:
      acceptedItems = []
      break;
  }
  
  const [{ canDrop }, drop] = useDrop({
    accept: acceptedItems,
    drop(item, monitor) {      

      const alreadyExists = item.variant

      if (!alreadyExists) {
        addItemToSection(item)
      }
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  })

  const getChecklistItems = () => {
    let formattedItems = [];
    
    if( type === "section" ) {
      sectionItems.map ( (item, index) => {   
        formattedItems.push(<ChecklistItemCard placed key={index} index={index} type={item.type} maintext={item.main_text} moveCard={ onReorderCards } onEdit={ () => {onEditCard(index)} } onDelete={ () => onRemove(index)} />)
        return true;
      }) 
    } else if( type === "checklist") {
      sectionItems.filter( item => {
        if ( item.id === 1 ) return false 
        return true
      }).map ( (item, index) => {   
        formattedItems.push(
          <ChecklistSectionCard placed id={item.id} key={index} index={index} adminTitle={item.admin_name} title={item.display_name} items={item.items} moveCard={ onReorderCards } onDelete={ () => onRemove(index)} />)
          return true;
        }) 
      }
      
      return formattedItems;
    }
    
    return (
      <div className={canDrop ? styles.eligibleDropzone : styles.dropzone} ref={drop}>
      { getChecklistItems() }

      { (sectionItems && type === "checklist") && (
        <div className={styles.placeholder}>
          <p className={styles.placeholderTitle}>Final Photo</p>
          <div className={styles.placeholderMeta}><PhotoCamera />Take photos of the assembled box, accessories and labels.</div>
        </div>
      )}
    </div>
  )
}

ChecklistDropzone.propTypes = {
  type: PropTypes.oneOf(['section', 'checklist']),
}

export default ChecklistDropzone