import React, { useState, useEffect } from 'react'
import Checkbox from '../../../components/Checkbox/Checkbox'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import APIClient from '../../../utils/api-client'
import validate from './EditStaffModalValidation';
import useForm from '../../../hooks/useForm'
import useBusy from '../../../hooks/useBusy'

const EditStaffModal = ({userID, onSubmit}) => {
  const { busy, setBusy, setNotBusy } = useBusy()
  const [user, setUser] = useState(null)

  useEffect( () => { 
    APIClient(`users/${userID}`).then( e => {
      const data = e.data;
      delete data.id
      setUser(data)
    })
  }, [userID])

  useEffect( () => { 
    setDefaults(user)  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onStaffSubmit = (form) => {
    setBusy()
    APIClient(`users/${userID}`, values, 'PUT').then( () => {
      onSubmit();
      setNotBusy();
    }).catch( error => {
      setNotBusy()
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }
  
  const { values, errors, handleChange, handleSubmit, setDefaults, handleServerError } = useForm(onStaffSubmit, validate);
  
  useEffect( () => {
    setDefaults({ admin: false })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <form onSubmit={ handleSubmit } >
        <Input label="Username" name="username" errors={errors.username} value={values.username} onChange={handleChange} />
        <Input label="Name" name="name" errors={errors.name} value={values.name} onChange={handleChange} />
        <Input label="Job Title" name="job_title" errors={errors.job_title} value={values.job_title} onChange={handleChange} />
        <Checkbox name="admin" label="admin account?" value={ values.admin } onChange={handleChange} />
        {/* <Input label="Password" name="password" errors={errors.password} value={values.password} onChange={handleChange} /> */}
        <Button loading={busy ? 'true' : 'false'} fullWidth type="submit" primary="true" size="large">Submit</Button>
      </form>
    </>
  )
}

export default EditStaffModal