import React from 'react'
import ChecklistPromptModal from './variants/ChecklistPromptModal/ChecklistPromptModal';
import ChecklistPhotoModal from './variants/ChecklistPhotoModal/ChecklistPhotoModal';
import ChecklistChecklistModal from './variants/ChecklistChecklistModal/ChecklistChecklistModal';

const EditChecklistItemModal = ({checklistItemProps, onSubmit}) => {  
  const onCheckoutItemSubmit = values => {
    onSubmit({
      index: checklistItemProps.index,
      obj: {
        type: checklistItemProps.type,
        ...values
      }
    })
  }
    
  const getChecklistModalContents = () => {
    switch (checklistItemProps.obj.type) {
        case 'prompt': 
          return <ChecklistPromptModal defaults={checklistItemProps.obj} onSubmit={onCheckoutItemSubmit} />
        case 'photo': 
          return <ChecklistPhotoModal defaults={checklistItemProps.obj} onSubmit={onCheckoutItemSubmit} />
        case 'checklist_item': 
          return <ChecklistChecklistModal defaults={checklistItemProps.obj} onSubmit={onCheckoutItemSubmit} />
        default:
          break;
    }
  }

  return getChecklistModalContents()
}

export default EditChecklistItemModal