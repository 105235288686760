import { useState } from 'react'

const useBusy = (initialMode = false) => {   
  const [busy, isBusy] = useState(initialMode)

  const setBusy = () => isBusy(true)
  const setNotBusy = () => isBusy(false)
  const toggle = () => isBusy(!busy) 
  
  return {
    busy, 
    setBusy, 
    setNotBusy, 
    toggle
  } 
}

export default useBusy

