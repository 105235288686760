import { useState, useEffect } from 'react';

const useForm = (callback, validate) => {

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasAttempted, setHasAttempted] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {        
      callback()
    } 
    setIsSubmitting(false)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect( () => {
    if( hasAttempted ) {
      setErrors(validate(values))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));   
    setIsSubmitting(true);
    setHasAttempted(true)
  };

  const handleChange = (event) => {
    event.persist();
    if( event.target.type === "checkbox") {
      setValues(values => ({ ...values, [event.target.name]: event.target.checked }));
    } else {
      setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    }
  };

  const generateData = (nameArr, value, index) => {
    let dataArr = {}
    
    if ( index !== null ) {

      // create blank or spread existing values 
      if (typeof values[nameArr[0]] === 'object') {
        dataArr = { ...values[nameArr[0]] }
      } else {
        dataArr = {}
      }

      // if theres a second level index
      if (nameArr[1] ) {
        if (typeof dataArr[index] === 'object') {
          dataArr[index] = { 
            ...dataArr[index],
            [nameArr[1]]: value
           }
        } else {
          dataArr[index] = { [nameArr[1]]: value }
        }
      }else {
        dataArr[index] = value
      }
      return dataArr
    }
  }

  const handleDynamicChange = (event) => {
    if ( event && event.type !== 'toggle')
      event.persist();
    
    let nameArr = event.target.name.split('.');

    if ( event.type === 'toggle') {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.checked, event.arrayid)
        }
      )
    } else if ( event.target.files ) {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.files[0], event.target.getAttribute('arrayid'))
        }
      )
    } else {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.value, event.target.getAttribute('arrayid'))
        }
      )
    }
  }

  const handleFileUpload = files => {
    setValues(values => ({ ...values, files: files }));
  }
 
  const handleDynamicFileUpload = files => {
    return
  }

  const setDefaults = (data) => {
    setValues(values => ({ ...values, ...data }))
  }

  const handleServerError = (errors) => {
    const errorObject = errors.reduce((errorObj, error) => {
      errorObj[error.field] = error.errors[0]
      return errorObj
    }, {})
    
    setErrors(errorObject)
  }

  return {
    handleChange,
    handleDynamicChange,
    handleSubmit,
    handleFileUpload,
    handleDynamicFileUpload,
    values,
    setValues,
    setDefaults,
    errors,
    handleServerError
  }
};

export default useForm;