import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import APIClient from '../../../utils/api-client'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Loader from '../../../components/Loader/Loader'

const RemoveChecklistSectionModal = ({sectionID, onSubmit}) => {

  const [stage, setStage] = useState(0)
  
  const onConfirmDelete = () => {
    setStage(1)
    APIClient(`checklists/sections/${sectionID}`, null, 'DELETE').then( e => {
      setStage(2)
      setTimeout( () => { onSubmit() }, 1500)
    }).catch( e => {
      setStage(0)
      console.warn('Something went wrong while removing the checklist section')
    })
  }

  const getContent = () => {

    if( stage === 0) {
      return (
      <>
        <p>Delete this checklist section? This action cannot be undone.</p>
        <Button fullWidth align='right' type="button" onClick={onConfirmDelete} color="primary" size="large">Submit</Button>
      </>
      )  
    } else if( stage === 1) {
      return (
        <div style={{ textAlign:'center'}}>
          <Loader circular inline />
          <p>Deleting...</p>
        </div>
      )
    } else {
      return(
        <div style={{ textAlign:'center'}}>
          <CheckCircleIcon />
          <p>Removed</p>
        </div>
      )
    }
  }

  return (
      <>
        { getContent() }
      </>
  )
}

export default RemoveChecklistSectionModal