import React from 'react'
import PropTypes from 'prop-types'
import { Warning, CheckBoxOutlined, PhotoCamera } from '@material-ui/icons';
import styles from './QaChecklistDetails.module.scss'
import { useModal } from '../../hooks/useModal'
import ImageLightbox from '../ImageLightbox/ImageLightbox'
const QaChecklistDetails = ({section}) => {

  let itemTag 

  const getIcon = type => {    
    switch (type) {
      case 'prompt':
        itemTag = 'Prompt'
        return <Warning />
      case 'photo':
        itemTag = 'Take a photo'
        return <PhotoCamera />
      case 'checklist_item':
        itemTag = 'Perform the following checks'
        return <CheckBoxOutlined />
      default:
        return <Warning />
    }
  }

  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()
  
  const handleMediaModal = mediaID => {
    setModalData(mediaID)
    toggleModal('pop')
  }

  return (
    <div className={styles.qaSectionBox}>
      <p className={styles.title}>
        { section.admin_name } { (section.video_recording && section.video_recording.required) && <span className="tableLinks__inline"> - <span onClick={() => handleMediaModal(section.video_recording.media_id)}>click to view video recording</span></span>}
      </p> 
      <ul className={styles.items}>
        { section.items.map( item => (
          <li>
            <p className={styles.itemLine}>{ getIcon(item.type) } <b className={styles.itemTag}>{itemTag}</b> - <span>{ item.main_text }</span> { item.media_id && ( <> - <span className="tableLinks__inline"><span onClick={() => handleMediaModal(item.media_id)}>click to view media</span></span></> )}</p>
            {item.type === 'checklist_item' && 
              <ul>
                {item.bullets.map( bullet => (
                  <li>{bullet.main_text}</li>
                ) )}
              </ul>
            }
          </li>
        ))}
      </ul>
      <ImageLightbox open={itemModalOpen === 'pop'} mediaID={modalData} handleClose={toggleModal}/>
    </div>
  )
}

QaChecklistDetails.propTypes = {
  section: PropTypes.array.isRequired,
}

export default QaChecklistDetails