import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import ApiClient from '../../../utils/api-client'
import validate from './NewFolderModalValidation';
import useForm from '../../../hooks/useForm'
import Loader from '../../../components/Loader/Loader'
import ModalSectionHeader from '../../../components/modalSectionHeader/modalSectionHeader'

const NewFolderModal = ({onSubmit}) => {

  const [saveProgress, setSaveProgress] = useState(null)
  
  const onFolderSubmit = (form) => {
    setSaveProgress('Saving folder')

    ApiClient('products/folders', values).then( response => {     
      if( !response.data.id ) return
      console.log("asodfoasd");
      onSubmit();
    }).catch( error => {
      setSaveProgress(null);
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }
  
  const { values, errors, handleChange, handleSubmit, handleServerError } = useForm(onFolderSubmit, validate);
  

  return (
    <>
    <ModalSectionHeader
      title="Folder details"
    />
    { saveProgress && (
      <Loader page title={saveProgress} />
    )}

      <form onSubmit={ handleSubmit } >
        <Input label="Folder Name" name="name" errors={errors.name } value={values.name} onChange={handleChange} />
        <Button fullWidth align='right' type="submit" color="primary" size="large">Submit</Button>
      </form>
    </>
  )
}

export default NewFolderModal