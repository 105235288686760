export default function validate(values) {
  let errors = {};

  if (!values.password) {
    errors.password = 'Please enter a password';
  } else if (values.password.length < 8 ) {
    errors.password = 'Password must be at least 8 characters'
  }

  if (values.password !== values.confirm) {
    errors.confirm = 'Passwords do not match'
  }
  
  return errors;
};