import { useState } from 'react'

export const useModal = (initialMode = false, inititalData = false) => {   
  const [modalOpen, setModalOpen] = useState(initialMode)
  const [modalData, setData] = useState(inititalData)  

  const setModalData = (data) => setData(data) 
  const toggle = (modal=false) => {

    if (modal) {
      setModalOpen(modal)
    } else {
      setModalOpen(false)
    }
  }

    return [modalOpen, toggle, modalData, setModalData] 
}

