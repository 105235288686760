import React, { useContext, useState } from 'react'
import { login as authLogin, logout as authLogout } from '../utils/auth-client'
import APIClient from '../utils/api-client'

const AuthContext = React.createContext(false)

function AuthProvider(props) {
  // logged in state
  const [ loggedIn, setLoggedIn ] = useState(false)
  
  // login/logout functions
  const login = form => authLogin(form).then(handleLoginStatusChange) // make a login request
  const register = form => {} // register the user
  const logout = () => authLogout().then(handleLoginStatusChange) // clear the token in localStorage and the user data

  if ( !loggedIn && window.localStorage.getItem('TCQAUser')) {
    
    APIClient('users/me').then( () => {
      
      setLoggedIn(true)
    }).catch( () => {
      logout()
      setLoggedIn(false)
    })
  }




  // if not logged in already and we can see a LS token
  if(!loggedIn && window.localStorage.getItem('TCQAUser')) {
    setLoggedIn(true)
  }

  // just toggles between logged in/logged out, depending on LS key
  const handleLoginStatusChange = () => {
    if(window.localStorage.getItem('TCQAUser')) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }


  // return the context provider with the logged in state and toggable functions
  return (
    <AuthContext.Provider value={{loggedIn, login, logout, register}} {...props} />
  )
}

// custom hook to access the above 
// use (for eg.) const { login } = useAuth()
const useAuth = () => useContext(AuthContext)

export {AuthProvider, useAuth}