import React, { useState, useEffect } from 'react'
import ImageUpload from '../ImageUpload/ImageUpload'
import styles from './ImageMultiUpload.module.scss'

const ImageMultiUpload = ({prepopFiles=null, onFileChange}) => {
  const [counter, setCounter] = useState(1)
  const [prepop, setPrepop] = useState([])
  const [files, setFiles] = useState([0])
  
  useEffect( () => {
    
    if( files && prepopFiles ) {
       let arr = [0]
      for( let i = 0; i < prepopFiles.length; ++i) {
        arr.push(i+1)
      }
      setPrepop(prepopFiles)
      setFiles(arr)
      setCounter(prepopFiles.length)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepopFiles])

  const handleImageChange = result => {
    if ( result ) {
      setFiles( [ ...files, counter ])
      let count = counter + 1
      setCounter( count )
      onFileChange(result)
    }
  }

  const handleImageRemove = indx => {
    let tempfiles = [ ...files ]
    tempfiles.splice(indx, 1)
    setFiles ([ ...tempfiles ])

    if (prepop.length > 0) {
      let pre = [ ...prepop]

      const ret = pre.splice(indx, 1)
      setPrepop([...pre])
      
      if ( ret[0] && ret[0].id ) {
        onFileChange(ret[0].id)
      }    
    }
  }
  
  return (
    <div className={styles.MultiUpload}>
      <div className={styles.uploadWrap}>
        { files.map ((file, idx) => {
          if (idx === files.length -1 ) {
            return (
              <div key={file} className={styles.uploadItem}>
                <ImageUpload index={idx}  fullWidth onUpdate={handleImageChange} onDelete={handleImageRemove} />
              </div>
            )
          } else {
            return (
              <div key={file} className={styles.uploadItem}>
                <ImageUpload index={idx} defaultImg={prepop[file] || null} fullWidth removable onUpdate={handleImageChange} onDelete={handleImageRemove} />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default ImageMultiUpload