import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import Alert from '../../components/Alert/Alert'
import Button from '../../components/Button/Button';
import validate from './LoginFormValidation'

import { loginForm } from './LoginForm.module.scss';
import { useAuth } from '../../contexts/AuthContext'

const LoginForm = () => {
  // input states  
  const [attemptingLogin, setAttemptingLogin] = useState(false)
  const [error, setError] = useState(null);
  
  // get login function from auth hook
  const { login } = useAuth()
  
  // handle errors
  const handleLoginError = e => {
    setAttemptingLogin(false)   
    // if (e.response.status >= 400 && e.response.status < 500) {
    //   setError('Unauthorised')
    // } else {
      setError('Unauthorised')
    // }
  }
  
  const handleLoginAttempt = () => {
    setAttemptingLogin(true)
    login( { username: values.username, password: values.password } ).catch( handleLoginError)
  }

  const { handleChange, handleSubmit, values, errors } = useForm(handleLoginAttempt, validate);
  
  return (
    <Paper className={loginForm}>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <Input name="username" label="Username" errors={errors.username} fullWidth variant="outlined" margin="dense" onChange={ handleChange } value={values.username}></Input>
          <Input name="password" label="Password" errors={errors.password} fullWidth type="password" variant="outlined" margin="dense" onChange={ handleChange } value={values.password}></Input>
          {error && (
            <Alert type="warning">{ error }</Alert>
          )}
          <Button type="submit" primary size="large" disabled={attemptingLogin}>{ attemptingLogin ? <CircularProgress size={28} /> : "Submit" }</Button>
        </form>       
    </Paper>
  );
}

export default LoginForm;