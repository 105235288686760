import React, {useState, useEffect} from 'react'
import { MenuItem, InputLabel } from '@material-ui/core';
import Select from '../Select/Select'
import APIClient from '../../utils/api-client'
import styles from './ProductFolderSelector.module.scss'

const ProductFolderSelector = ({labelTitle, noneTitle, value, onFolderIdChanged, onChange}) => {

  const [folders, setFolders] = useState([])
  
  const handleFolderChange = (event) => {
    if (typeof onFolderIdChanged === 'function') {
      onFolderIdChanged(event.target.value);
    }
    if (typeof onChange === 'function') {
      onChange(event);
    } 
  }
  
  useEffect(() => {
    APIClient(`products/folders`).then((e) => {
      const data = e.data;
      setFolders(data);
    });
  }, []); 

  return (
    <>
        <div className={styles.folderRow}>
          <InputLabel className={styles.folderLabel} id="product-folder-label">{labelTitle || 'Folder'}</InputLabel>
        <Select
          className={styles.folderSelector}
          labelId="product-folder-label"
          label="Folder"
          value={value || ''}
          name="folder_id"
          onChange={handleFolderChange}
        >
          <MenuItem key='' value=''>{noneTitle}</MenuItem>   

          { !folders ? (
            <MenuItem>Getting folders..</MenuItem>
          ) : 
            folders.map( folder => (
              <MenuItem key={folder.id} value={folder.id}>{folder.name}</MenuItem>
            ))
          }       
        </Select>
        </div>
    </>
  )
}

export default ProductFolderSelector