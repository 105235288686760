import React, {useState, useEffect} from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import APIClient from '../../../utils/api-client'
import validate from './EditFolderModalValidation';
import useForm from '../../../hooks/useForm'
import Loader from '../../../components/Loader/Loader'
import { Delete } from '@material-ui/icons'
import { IconButton } from '@material-ui/core';

const EditFolderModal = ({folderID, onSubmit}) => {

  const onFolderSubmit = (form) => {
    setSaveProgress('Saving folder')

    const args = {
      name: values.name
    }

    APIClient(`products/folders/${folderID}`, args, 'PUT').then( response => {     
      if( !response.data.id ) { 
        return
      }
      onSubmit();
    }).catch( error => {
      setSaveProgress(null);
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }


  const { values, setDefaults, errors, handleChange, handleSubmit, handleServerError} = useForm(onFolderSubmit, validate);

  const [saveProgress, setSaveProgress] = useState(null)
  const [deleteWarning, setDeleteWarning] = useState(false)
  const [productsAssignedWarning, setProductsAssignedWarning] = useState(false)
  const [folder, setFolder] = useState(null)

  useEffect( () => { 
    APIClient(`products/folders/${folderID}`).then( e => {
      const data = e.data;
      console.log(data);
      setFolder(data)
    })
  }, [folderID])

  useEffect( () => { 
    setDefaults(folder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder])

  const onSelectDelete = () => {

    if (folder['product_count'] > 0) {
      setProductsAssignedWarning(true)
    } else {
      setDeleteWarning(true)
    }

  }

  const onCancelDelete = () => {
    setDeleteWarning(false)
  }

  const onConfirmDelete = () => {
    setDeleteWarning(false)

    APIClient(`products/folders/${folderID}`, null, 'DELETE').then( e => {
      onSubmit()
    })
  }

  return (
    <>
      { saveProgress && (
        <Loader page title={saveProgress} />
      )}
      <form onSubmit={ handleSubmit } >
        <Input label="Folder Name" name="name" errors={errors.name} value={values.name} onChange={handleChange} />
        {productsAssignedWarning && <h4>Remove any assigned products from this folder before deleting.</h4>}
        {deleteWarning ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Are you sure you want to delete this folder?
            <Button key="buttonCancelDelete" type="button" onClick={onCancelDelete}>Cancel</Button>
            <Button key="buttonConfirmDelete" type="button" onClick={onConfirmDelete}>Delete</Button>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton type="button" onClick={onSelectDelete}><Delete/></IconButton>
            <Button type="submit" color="primary" size="large">Save</Button>
          </div>
        ) 
        }
      </form>
    </>
  )
}

export default EditFolderModal