import React, { useState, useEffect } from 'react'
import styles from './Toggle.module.scss'

const Toggle = ({id = null, name, value=false, options, onChange}) => {

  let [selected, setSelected] = useState(value)
  let [args, setArgs] = useState( () => {
    let a = {
      type: 'toggle',
      target: {
        name: name,
        checked: selected
      }
    }

    if ( id !== null ) { a.arrayid = id }
    return a
  })
  

  useEffect( () => {
    setArgs({
      ...args,
      target: {
        ...args.target,
        checked: selected
      }
    })  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])
  
  useEffect( () => {
    onChange (args)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])
  
  return(
    <div className={styles.toggleWrap}>
      { options.map ( (option, index) => (
        <label key={index} onClick={() => setSelected(index ? true : false)} className={ (selected &&  index === 1) || (!selected && index === 0) ? styles.toggleOptionSelected : styles.toggleOption}>{option}</label>
      ))}
    </div>
  )
}

export default Toggle