
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable'
import APIClient from '../../utils/api-client'
import { Tooltip } from '@material-ui/core'
import FormModal from '../../components/FormModal/FormModal'
import RemoveChecklistSectionModal from './modals/removeChecklistSectionModal'
import DateTime from 'luxon/src/datetime.js'
import { useModal } from '../../hooks/useModal'
import SectionHeader from '../../components/SectionHeader/SectionHeader'

const ChecklistSectionLayout = withRouter(({history }) => {
  const [checklists, setChecklists] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [args, setArgs] = useState({
    page: 1,
    limit: 10,
  });
  
  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()

  useEffect( () => {
    getChecklistSectionData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  const getChecklistSectionData = () => {
    APIClient('checklists/sections', args, 'GET').then( e => {
      let formattedItems = []
      e.data.results.map( row => {
        formattedItems.push( {
          productName: row.admin_name,
          productCount: `${row.products.length} Products`,
          lastUpdated: DateTime.fromSQL(row.updated_at).toFormat('dd/LL/yyyy HH:mm'),
          actions: getLinks(row.id, row.products.length)
        })
        return true
      })
      setChecklists(formattedItems) 
      setTotalCount(e.data.pagination.total_count)

    }).catch( e => {
      setChecklists([])
    })
  }
  
  const tableHeaders = [
    'Section Name',
    'Products',
    'Last Updated',
    null
  ]

  const getLinks = (id, products) => {
    return (
      <span className="tableLinks">
        <Link to={`/checklists/section/edit/${id}`}>EDIT</Link>
    {products === 0 ? <span onClick={() => deleteSection(id)}>DELETE</span> : <Tooltip title="Cant remove sections attached to products"><span className='tableLink--disabled'>DELETE</span></Tooltip> }
        <Link to={`/checklists/section/duplicate/${id}`}>DUPLICATE</Link>
      </span> 
    )
  }

  const handleArgsChange = (args) => {
    setArgs( args )
  }

  const handleNewSectionClick = () => {
    history.push('/checklists/section/new')
  }
  
  const deleteSection = (sectionID) => {
    setModalData(sectionID)
    toggleModal('delete')
  }

  const handleDeleteSection = () => {
    toggleModal()
    getChecklistSectionData()
  }

  return (
    <>
      <SectionHeader 
        header='Block Library'
        description='Add new and edit existing blocks. These can be combined to create Quality Assurance checklists for products.'
        withButton="Add new" 
        onButtonClick={ handleNewSectionClick } 
      />  
      <PaginatedTable headers={tableHeaders} data={checklists} productFilter totalCount={totalCount} onArgsChange={handleArgsChange} />
      <FormModal title="Delete Checklist Section" isActive={itemModalOpen === 'delete'} handleClose={toggleModal}>
        <RemoveChecklistSectionModal sectionID={modalData} onSubmit={ handleDeleteSection } />
      </FormModal>
    </>
  );
})

export default ChecklistSectionLayout;