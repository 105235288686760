import React, { useState } from 'react'
import APIClient from '../../utils/api-client'
import { Dialog, DialogContent } from '@material-ui/core'
import style from './ImageLightbox.module.scss'
import Loader from '../Loader/Loader'
const ImageLightbox = ({open, mediaID, handleClose}) => {

  const [media, setMedia] = useState(null)

  const handleGetImage = () => {
    if (mediaID) {
      APIClient(`media/${mediaID}`).then( e => {
        setMedia(e.data)
      })
    }
  }
  
  const getMedia = () => {
    if(media.src) {

      if (!media.mime_type) {
        return <p>Unrecognised file type.</p>
      }

      // if image
      if( media.mime_type.includes('image')) {
        return <img src={ media.src } alt="TruCorp QA" />
      } else if( media.mime_type.includes('video')) {
        return <video controls><source src={media.src} type={media.mime_type} /></video>
      }
      
    } else {
      if( media.status === 'processing' ) {
        return <p>This media is currently processing. Check back soon to view it.</p>
      } else {
        return <p>Error loading media.</p>
      }
    }
  }

  return(
    <Dialog open={open} maxWidth='xl' className={style.mediaLightbox} onClose={handleClose} onEnter={handleGetImage}>
      { media ? (
        <DialogContent>
         { getMedia() }
        </DialogContent>
      ) : (
        <DialogContent>
          <Loader circular />
        </DialogContent>
      )}
    </Dialog>
  )

}

export default ImageLightbox