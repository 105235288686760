import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

export const AuthRoute = ({ component: Component, ...rest }) => {

  // get login state
  const { loggedIn } = useAuth()

  // got to route if logged in, if not redirect to home (login screen when logged out)
  return (
    <Route {...rest} render={(props) => (
      loggedIn
        ? <Component {...rest} {...props} />
        : <Redirect to='/' />
    )} />
  )
}