
import React, { useState, useEffect } from 'react';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable'
import APIClient from '../../utils/api-client'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import { useModal } from '../../hooks/useModal'
import FormModal from '../../components/FormModal/FormModal'
import NewStaffModal from './modals/NewStaffModal';
import EditStaffModal from './modals/EditStaffModal';
import EditStaffPasswordModal from './modals/EditStaffPasswordModal';

const StaffLayout = () => {
  const [users, setUsers] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [args, setArgs] = useState({
    page: 1,
    limit: 10,
  });
  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()
  
  useEffect( () => {
    getUserData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  const getUserData = () => {
    APIClient('users', args, 'GET').then( e => {

      let formattedItems = []

      e.data.results.map( row => {
        formattedItems.push( {
          name: row.name,
          job_title: row.job_title,
          username: row.username,
          admin_user: row.admin,
          actions: getLinks(row.id)
        })
        return true
      })
      setUsers(formattedItems) 
      setTotalCount(e.data.pagination.total_count)
    }).catch( e => { setUsers([]) }
    )
  }
  
  const getLinks = ( id ) => {
    return (
      <span className="tableLinks">
        <span onClick={ () => handleEditUser(id) }>EDIT</span>
        <span onClick={ () => handleEditUserPassword(id) }>RESET PASSWORD</span>
      </span>  
    )
  }

  const tableHeaders = [
    'Name',
    'Job Title',
    'Username',
    'Admin User',
    null
  ]

  const handleEditUser = e => {
    setModalData(e);
    toggleModal('edit')
  }

  const handleEditUserPassword = e => {
    setModalData(e)
    toggleModal('password')
  }

  const handleArgsChange = (args) => {
    setArgs( args )
  }

  const handleNewStaffSubmit = () => {
    toggleModal()
    getUserData()
  }
  
  const handleEditStaffSubmit = () => {
    toggleModal()
    getUserData()
  }
  
  return (
    <>
      <SectionHeader 
        header='Staff'
        description='Add staff so they can access the QA application and process QA tests. Manage staff administrative access.'
        withButton="Add new" 
        onButtonClick={() => toggleModal('add')} 
      />  
      <PaginatedTable headers={tableHeaders} data={users} totalCount={totalCount} onArgsChange={handleArgsChange} />
      <FormModal title="Add New Staff" isActive={itemModalOpen === 'add'} handleClose={toggleModal}>
        <NewStaffModal onSubmit={ handleNewStaffSubmit } />
      </FormModal>
      <FormModal title="Edit Staff" isActive={itemModalOpen === 'edit'} handleClose={toggleModal}>
        <EditStaffModal userID={modalData} onSubmit={ handleEditStaffSubmit } />
      </FormModal>
      <FormModal title="Set new password" isActive={itemModalOpen === 'password'} handleClose={toggleModal}>
        <EditStaffPasswordModal userID={modalData} onSubmit={ handleEditStaffSubmit } />
      </FormModal>
    </>
  );
}

export default StaffLayout;