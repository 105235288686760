import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './StatsCard.module.scss'
import TopProducts from '../../components/TopProducts/TopProducts';

const StatsCard = ({number, durationTitle, numberTitle, topProducts}) => {

  var cardStyle = {
    minHeight: '420px'
}

  return (
      <div className={styles.statsCard}>
        <Card style={cardStyle}>
          <CardContent>
            <h3>{durationTitle}</h3>
            <div className={styles.statsRow}><h1>{number}</h1><h4>{numberTitle}</h4></div>
            <TopProducts topProducts={topProducts} />
          </CardContent>
        </Card>
      </div>
  )
}

export default StatsCard;