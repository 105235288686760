import React from 'react';

import Chip from '@material-ui/core/Chip';
import styles from './TopProducts.module.scss'

const TopProducts = ({ topProducts }) => {
  
  if (!topProducts) {
    
  }

  return (
    <div className="top-products">
      <h4>Top Products</h4>
      { topProducts ? (
        <ul className={styles.productList}>
        {topProducts.map(product => (
          <li key={product.product} className={styles.productItem}>
            <Chip label={product.count} className={styles.productListChip} />
            <span className="product-name">{product.product}</span>
          </li>
        ))}
      </ul>
      ) : (
        <p>-</p> // or any other appropriate loading indicator
      )}
      
    </div>
  );
};

export default TopProducts;