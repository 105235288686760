import React, {useState, useEffect} from 'react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import APIClient from '../../../utils/api-client'
import validate from './EditProductModalValidation';
import useForm from '../../../hooks/useForm'
import ModalSectionHeader from '../../../components/modalSectionHeader/modalSectionHeader'
import ImageMultiUpload from '../../../components/ImageMultiUpload/ImageMultiUpload';
import Loader from '../../../components/Loader/Loader'
import ProductFolderSelector from '../../../components/ProductFolderSelector/ProductFolderSelector';

const EditProductModal = ({productID, onSubmit}) => {

  const onProductSubmit = (form) => {
    setSaveProgress('Saving product')

    const args = {
      name: values.name,
      sku: values.sku,
      serial_number_prefix: values.serial_number_prefix,
      distinguishing_feature: values.distinguishing_feature,
      folder_id: values.folder_id === '' ? null : values.folder_id,
    }

    APIClient(`products/${productID}`, args, 'PUT').then( response => {     
      if( !response.data.id ) { 
        return
      }

      if( toUpload.length > 0 || toDelete.length > 0 ) {
        handleFileUploads(response.data.id);
      } else {
        setSaveProgress('complete')
        setTimeout( () => {
          setSaveProgress(null)
          onSubmit();
        }, 1500)
      }      
    }).catch( error => {
      setSaveProgress(null);
      if ( error.response.data.message && error.response.data.message.errors ) {
        handleServerError(error.response.data.message.errors)
      }
    })
  }

  const handleFileUploads = id => {
    setSaveProgress('Handling images')
    let promises = [];
    
    toUpload.map ( (file, i) => {
      let FD = new FormData()
      FD.append('file', file)
      promises.push( APIClient(`products/${id}/media`, FD, 'MEDIA') )
      return true
    })

    toDelete.map ( (file, i) => {
      promises.push( APIClient(`products/${id}/media/${file}`, null, 'DELETE') )
      return true
    })

    Promise.all( promises ).then( val => {
      setSaveProgress('complete')
      setTimeout( () => {
        setSaveProgress(null)
        onSubmit();
      }, 1500)
    }).catch( vals => {
      setSaveProgress('error')
      setTimeout( () => {
        setSaveProgress(null)
        onSubmit();
      }, 1500)
    })
  }

  const { values, setDefaults, errors, handleChange, handleSubmit, handleServerError} = useForm(onProductSubmit, validate);

  const [saveProgress, setSaveProgress] = useState(null)
  const [files, setFiles] = useState(null)
  const [toDelete, setToDelete] = useState([])
  const [toUpload, setToUpload] = useState([])

  useEffect( () => { 
    APIClient(`products/${productID}`).then( e => {
      const data = e.data;
      console.log(data);
      delete data.id
      if (data.folder) {
        data.folder_id = data.folder.id
      }

      setDefaults(data)
    
    if ( data && data.media ) {
      setFiles( [...data.media] )
    }

    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productID])

  const handleFileSelection = (file) => {
    
    // any that are removed that already exist
    const tgt = files.findIndex( (ele) => ele.id === file )

    if ( typeof file === 'number' && tgt !== -1) {
      setToDelete( [...toDelete, file] )
    } else {

      let fileObj
      if( file.target.files[0] ) {
        fileObj = file.target.files[0]
      } else if( file.dataTransfer.files[0] ) {
        fileObj = file.dataTransfer.files[0]
      }

      setToUpload( [ ...toUpload, fileObj])
    }    
  }

  return (
    <>
      { saveProgress && (
        <Loader page title={saveProgress} />
      )}
      <ModalSectionHeader
        title="Product details"
      />
      <form onSubmit={ handleSubmit } >
        <Input label="Product Name" name="name" errors={errors.name} value={values.name} onChange={handleChange} />
        <Input label="Product SKU" name="sku" errors={errors.sku} value={values.sku} onChange={handleChange} />

        <ProductFolderSelector name="folder_id" noneTitle="No folder" value={values.folder_id} onChange={ handleChange }/>

        <Input label="Distinguishing feature (optional)" name="distinguishing_feature" errors={errors.distinguishing_feature} value={values.distinguishing_feature} onChange={handleChange} />        
        <ImageMultiUpload name="images" prepopFiles={files} onFileChange={handleFileSelection} />
        <Button fullWidth type="submit" color="primary" size="large">Submit</Button>
      </form>
    </>
  )
}

export default EditProductModal