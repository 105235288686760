import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, MenuItem} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Input from '../Input/Input'
import Select from '../Select/Select'
import Loader from '../Loader/Loader';
import styles from './PaginatedTable.module.scss';
import APIClient from '../../utils/api-client';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

/* eslint-disable */

const PaginatedTable = ({
  headers, 
  data, 
  defaultRows=10, 
  productFilter=false, 
  stringFilter=false, 
  totalCount=0, 
  onArgsChange,

}) => {
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRows);

  const [filters, setFilters] = useState([])
  const [hideFilter, setHideFilter] = useState(false) 

  const [args, setArgs] = useState({
    page: 1,
    limit: defaultRows,
  })

  const getCellData = data => {
    if ( data === null ) {
      return <Loader />
    } else {
      switch ( typeof data ) {
        case 'boolean':
          return <span>{data ? <CheckIcon /> : <ClearIcon />}</span>
        default:
          return <span>{ data }</span>
      }
    }
  }

  useEffect( () => {
    APIClient('products/basicList').then( e => {
      setFilters(e.data)
    }).catch( error => {
      console.warn('There was a problem setting the products filter')
      setHideFilter(true)
    })

    if ( productFilter ) {
      setArgs({
        ...args,
        product_id: ''
      })
    }
  }, [])

  useEffect( () => {
    onArgsChange( args )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args])

  const getTableContents = () => {
    if(!data ) {
      let ar = Array.from(headers, x => <TableCell key={x}><Loader /></TableCell> )
      return <TableRow>{ ar }</TableRow>;
    } else {
      if( data.length > 0 ) {
        let returnedData = [];
       // (!filter ? data : data.filter(row => row['name'].toLowerCase().includes(filter.toLowerCase())))       
        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        data.map((row, index) => {
          returnedData.push(
            <TableRow key={index}>
              { Object.keys(row).map( itm => (
                <TableCell key={itm}>{ getCellData(row[itm]) }</TableCell>
              ))}      
            </TableRow>
          )
          return true
        })
        return returnedData
      } else { 
        return (
          <TableRow>
            <TableCell>
              No results!
            </TableCell>
          </TableRow>
        )
      }
    }
  }

  const handleSerialSearch = e => {
    setArgs({
      ...args,
      serial_code: e.target.value
    })
  }

  const handleChangePage = (event, newPage) => {
    setArgs( {
      ...args,
      page: newPage+1
    })
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value));
    
    setArgs( {
      ...args,
      limit: parseInt(event.target.value),
      page: 1
    })
  };

  const handleProductFilterChange = e => {
    setPage(0);
    setArgs( {
      ...args,
      page: 1,
      product_id: e.target.value
    })
  }

  return (
    <>
      { (productFilter || stringFilter) && (
      <div className={styles.filters}>
        { productFilter && !hideFilter && (
        <>
        <p>Filter by product</p>
        <Select
          className={styles.filterByProduct}
          allItemsOption={true}
          value={args.product_id || ''}
          onChange={handleProductFilterChange}
        >
          { !filters ? (
            <MenuItem>Getting products..</MenuItem>
          ) : 
            filters.map( filt => (
              <MenuItem key={filt.id} value={filt.id}>{filt.name}</MenuItem>
            ))
          }          
        </Select>
        </>
        )}
        { stringFilter && (
        <Input label="Serial Number search" className={styles.filterByCol} onChange={handleSerialSearch} value={args.serial_code} />
        )}
      </div>
      )}
      <Paper>      
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              {headers.map((row, idx) => (
                <TableCell key={idx}>{ row }</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { getTableContents() }
          </TableBody>
        </Table>
        { totalCount > 1 && (
        <TablePagination
          rowsPerPageOptions={[10,20,50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        )}
      </Paper>
    </>
  )
}

PaginatedTable.propTypes = {
}

export default PaginatedTable;