import React, { useState, useEffect } from 'react'
import DateTime from 'luxon/src/datetime.js'
import APIClient from '../../utils/api-client'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import LabelledDetail from '../../components/LabelledDetail/LabelledDetail'
import Loader from '../../components/Loader/Loader'
import QaChecklistDetails from '../../components/QaChecklistDetails/QaChecklistDetails'
import Box from '@material-ui/core/Box'
import Select from '../../components/Select/Select'
import styles from './ProductDatabaseDetailsLayout.module.scss'

const ProductDatabaseDetailsLayout = ({match, history}) => {
  const [product, setProduct] = useState({})
  const [qaDetail, setQaDetail] = useState([])
  const [pastDates, setPastDates] = useState(null)

  const [qaChecklistInfo, setChecklistInfo] = useState([])
  useEffect( () => { 
    APIClient(`qas/${match.params.productID}`).then( e => {
      const data = e.data;
      
      setProduct( {
        name: data.checklist.product.name,
        serial: data.serial_code,
        historical_serial: data.historical_serial_code,
        date: DateTime.fromSQL(data.completed_at).toFormat('dd LLL yyyy @ HH:mm'),
        operator: data.user.name,
        retest_reason: data.retest_reason
      })

      APIClient(`qas?serial_code=${data.serial_code}&limit=100`).then( e => {
        const data = e.data.results.sort((a, b) => a.completed_at < b.completed_at ? 1 : -1);
        setPastDates(data)
      })

      setQaDetail( data.checklist.checklist_sections)
      setChecklistInfo( data.checklist)
    })

  }, [match])

  const handleDateChange = e => {
    setProduct({name: product.name, serial: product.serial})
    history.push(`/product-database/details/${e.target.value}`)
  }
  
  return ( 
    <>
      <SectionHeader 
        header='Registered Product Details' 
        description={<><b>The following details show a snapshot of the test that was performed on the date below.</b><br/>Any changes to the testing requirements after the date below will not be included.</>}
      /> 

      <div className={styles.detailContainer}>
        <Box display="flex" className={styles.QaDetails}>
          <LabelledDetail label="Product Name" className={styles.name}>
            <p>{ !product.name ? <Loader large /> : product.name }</p>
          </LabelledDetail>
          <LabelledDetail label="Serial Number">
            <p>{ !product.serial ? <Loader large /> : product.serial }</p>
          </LabelledDetail>
          {product.historical_serial && (
            <LabelledDetail label="Historical Serial Number" className={styles.name}>
            <p>{ product.historical_serial }</p>
          </LabelledDetail>
          )}
        </Box>
      </div>
      <div className={styles.dateSelector}>
        <label><strong>QA Test</strong></label>
        {pastDates ? (
          <Select native
              fullWidth
              label="Bullet Description" 
              array={pastDates} 
              name={`bullets.type`}
              onChange={handleDateChange}
          >
            { pastDates.map( (ele, i) => (
              <option key={i} value={ele.id}>{DateTime.fromSQL(ele.completed_at).toFormat('dd LLL yyyy @ HH:mm')}{i===0 && ' (latest)'}</option>
            ))}
          </Select>
        ) : (
          <Loader />
        )}
      </div>
      <div className={styles.detailContainer}>
        <Box display="flex" className={styles.OperatorDetails}>
          <LabelledDetail label="QA Date" className={styles.date}>
            <p>{ !product.date ? <Loader large /> : product.date }</p>
          </LabelledDetail>
          <LabelledDetail label="QA Operator" className={styles.operator}>
            <p>{ !product.operator ? <Loader large /> : product.operator }</p>
          </LabelledDetail>
          <LabelledDetail label="QA Result" className={styles.pass}>
            <p className={styles.passed}>{product.date ? 'PASSED' : <Loader large />}</p>
          </LabelledDetail>
          <LabelledDetail label="Test Note" small>
            <p>{!product.retest_reason ? (product.date ? <span className={styles.muted}>none supplied</span> : <Loader />) : product.retest_reason}</p>
          </LabelledDetail>
          
        </Box>
      </div>

      <Box display="flex" flexWrap="wrap" className={[styles.detailContainer]}>
        <LabelledDetail underline label="QA Tests Performed">
          { qaDetail.map( detail => (
            <QaChecklistDetails section={detail} />
          ))}
        </LabelledDetail>
        <p className={styles.qaDate}>QA test published on: {qaChecklistInfo.updated_at}</p>
      </Box>

    </>
  )
}

export default ProductDatabaseDetailsLayout