export default function validate(values) {
    let errors = {};
  
    if (!values.main_text) {
      errors.main_text = 'A name is required';
    }  

    if (values.bullets) {
      Object.keys(values.bullets).forEach((key, index) => {
                
        if (!values.bullets[key].main_text) {
          errors.bullets = {
            ...errors.bullets,
            [key]: {main_text: `Content is required for bullet ${ parseInt(key)+1 }, or remove it`}
          } 
        }
      });
    }

    if (values.criteria) {
      Object.keys(values.criteria).forEach((key, index) => {
        if (!values.criteria[key].main_text) {
          errors.criteria = {
            ...errors.criteria,
            [key]: {main_text: `Content is required for criteria ${ parseInt(key)+1 }, or remove it`}
          } 
        } else if( typeof values.criteria[key].acceptable !== 'boolean' ) {
          errors.criteria = {
            ...errors.criteria,
            [key]: {main_text: `Please specify whether this criteria is acceptable/unacceptable`}
          } 
        }
      });
    }
    
    return errors;
  };