import React from 'react'
import Input from '../Input/Input'
import Select from '../Select/Select'
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './BulletPointLine.module.scss'
const BulletPointLine = ({id, values, errors, handleChange, onRemove}) => {
  
  const types = [
    { value: 'info', label: 'Information'},
    { value: 'action', label: 'Action'},
    { value: 'warning', label: 'Warning'},
  ]

  const handleRemoval = () => {
    onRemove(id)
  }

  return (
    <div className={styles.bulletLine}>
      <div className={styles.bulletDesc}>
        <Input label="Bullet Description" key={id} array={id} name={`bullets.main_text`} errors={errors && errors.main_text } value={values && values.main_text ? values.main_text : ''}  onChange={handleChange} />
      </div>
      <div className={styles.bulletType}>
        <Select native
          fullWidth
          label="Bullet Description" 
          key={id} 
          array={id} 
          name={`bullets.type`}
          onChange={handleChange}
      >
        { types.map( (ele, i) => (
          <option key={i} value={ele.value}>{ele.label}</option>
        ))}
      </Select>
      </div>
      <div className={styles.bulletRemove}>
        <DeleteIcon onClick={handleRemoval} />
      </div>
    </div>
  )
}

export default BulletPointLine