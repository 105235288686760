import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

import Theme from './containers/Theme/Theme'
import Header from './containers/Header/Header';
import Main from './containers/Main/Main';

import { StylesProvider } from '@material-ui/core/styles';
import './index.scss';

ReactDOM.render(
  <AuthProvider>
    <StylesProvider injectFirst>
      <Theme>
        <div id="layout">
          <Router basename={process.env.REACT_APP_BASE_DIRECTORY}>
            <Header />
            <Main />
          </Router>
        </div>
      </Theme>
    </StylesProvider>
  </AuthProvider>
, document.getElementById('root'));
