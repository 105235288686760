import React, { useState, useEffect, useCallback } from 'react';
import APIClient from '../../utils/api-client'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import { useModal } from '../../hooks/useModal'
import FormModal from '../../components/FormModal/FormModal'
import NewFolderModal from './modals/NewFolderModal'
import EditFolderModal from './modals/EditFolderModal'
import Loader from '../../components/Loader/Loader'
import ProductFolderListitem from '../../components/ProductFolderListItem/ProductFolderListItem';

const ProductFoldersLayout = () => {
  const [loading, setLoading] = useState(true)
  const [folders, setFolders] = useState([])

  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()

  let args = {}

  const getFolderData = useCallback(() => {
    APIClient('products/folders', args, 'GET').then( e => {
      setLoading(false)
      setFolders(e.data) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })}, [])

  useEffect(() => {
    getFolderData();
  }, [getFolderData]);

  const handleNewFolderSubmit = () => {
    toggleModal()
    getFolderData()
  }

  const handleEditFolderSubmit = () => {
    toggleModal()
    getFolderData()
  }

  const handleEditFolder = e => {
    setModalData(e);
    toggleModal('edit')
  }

  const displayFolderData = () => {
    if( loading ) {
        return <Loader page />
    } else {
      if( folders.length > 0 ) {
        let displayFolder = [];
        folders.forEach( (folder) => {
          displayFolder.push(<ProductFolderListitem key={folder.id} id={folder.id} folder={folder} onEdit={() => handleEditFolder(folder.id)}></ProductFolderListitem>)
        })
        return displayFolder
      } else {
        return <p>no folders found!</p>
      }
    }
  }

    
  return (
    <>
      <SectionHeader 
        header='Folders'
        withButton="Add new" 
        onButtonClick={() => toggleModal('add')} 
      /> 
      
      { displayFolderData() }

      { <FormModal 
        title="Add New Folder" 
        description='Add a new folder. Once this has been created you can add products to this folder.'
        isActive={itemModalOpen === 'add'} 
        handleClose={toggleModal}
      >
        <NewFolderModal onSubmit={ handleNewFolderSubmit } />
      </FormModal> }
      {  <FormModal title="Edit Folder" isActive={ itemModalOpen === 'edit'} handleClose={toggleModal}>
        <EditFolderModal folderID={modalData} onSubmit={ handleEditFolderSubmit } />
      </FormModal>  }
    </>
  );
}

export default ProductFoldersLayout;