import React, { useState, useEffect, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { useModal } from '../../hooks/useModal'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ChecklistDropzone from '../../components/ChecklistDropzone/ChecklistDropzone'
import FormModal from '../../components/FormModal/FormModal'
import Input from '../../components/Input/Input'
import Loader from '../../components/Loader/Loader'
import NewChecklistItemModal from './modals/NewChecklistItemModal';
import EditChecklistItemModal from './modals/EditChecklistItemModal';
import APIClient from '../../utils/api-client'
import CardPool from '../../components/CardPool/CardPool'
import Checkbox from '../../components/Checkbox/Checkbox'

import styles from './CreateChecklistSectionLayout.module.scss'

const CreateChecklistSectionLayout = ({match, editType="NEW", history}) => {

  const [saveProgress, setSaveProgress] = useState(null)
  const [currentChecklist, setCurrentChecklist] = useState(null) 
  const [sectionName, setSectionName] = useState('') 
  const [displayName, setDisplayName] = useState('') 
  const [requireVideo, setRequireVideo] = useState(false) 
  const [checklistItems, setChecklistItems] = useState([])
  
  const [loading, setLoading] = useState( () => (match && match.params.sectionID) ? true : false)

  const [itemModalOpen, toggleModal, modalData, setModalData] = useModal()


  let pageTitle, pageDescription

  switch (editType) {
    case 'EDIT':
      pageTitle = 'Edit Block'
      pageDescription = 'Modify the Block by adding or removing checklist items. This will not affect any current product Quality Assurance tests that have already started.'
      break;
    case 'DUPLICATE':
      pageTitle = 'Duplicate Block'
      pageDescription = 'Create a Block from a duplicate. Saving this will save it as a new reusable block. This will not override the original.'
      break;
    default:
      pageTitle = "Create New Block"
      pageDescription = 'Create a new Block by adding and removing test items. Once this has been created you will be able to use this block to create a product\'s Quality Assurance test.'
      break;
  }

  const handleSaveSectionClick = async () => {

    setSaveProgress('saving checklist section...')

    const uploadedChecklistItems = await prepareChecklistItems()

    Promise.all( uploadedChecklistItems ).then( result => {
      const args = {
        admin_name: sectionName,
        display_name: displayName,
        video_recording: requireVideo,
        items: checklistItems
      }
    
      if ( !currentChecklist ) {
        APIClient('checklists/sections', args).then( e => {
          setSaveProgress('complete')
          setTimeout( () => {
            history.push(`/checklists`)
          }, 1500)
        }).catch( e => {
          setSaveProgress('error')
          setTimeout( () => {
            setSaveProgress(false)
          }, 1500)
          console.warn('There was an error saving the checklist section')
        })
      } else {
        APIClient(`checklists/sections/${currentChecklist}`, args, 'PUT').then( e => {
          setSaveProgress('complete')
          setTimeout( () => {
            history.push(`/checklists`)
          }, 1500)
        }).catch( e => {
         // error
         setSaveProgress('error')
          setTimeout( () => {
            setSaveProgress(false)
          }, 1500)
         console.warn('There was an error saving the checklist section')
        })
      }
    }).catch(error => { 
      console.error(error.message)
      setSaveProgress('error')
      setTimeout( () => {
        setSaveProgress(false)
      }, 1500)
    });
  }

  const prepareChecklistItems = async () => {

    const complete = [...checklistItems]
    let mediaPromises = []

    for (let [index, checkItem] of complete.entries()) {

      if ( checkItem.type === 'checklist_item' && checkItem.criteria) {

        mediaPromises = [];
        
        let criteriaItems = Object.entries( checkItem.criteria )


        for( const criteriaItem of criteriaItems) {

          if( criteriaItem[1].media_id ) {
            const FD = new FormData()
            FD.append('file', criteriaItem[1].media_id)

            const mediaID = await APIClient('checklists/sections/media', FD, "MEDIA")

            if (mediaID.data.id) {
              mediaPromises.push(new Promise( (resolve, reject) => {
                complete[index].criteria[criteriaItem[0]].media_id = mediaID.data.id
                setChecklistItems( [ ...complete ] )
                resolve()
              }))
            } 

          } else if( criteriaItem[1].media ) {
            mediaPromises.push(new Promise( resolve => {
              complete[index].criteria[criteriaItem[0]].media_id = criteriaItem[1].media.id
              setChecklistItems( [ ...complete ] )
              resolve()
            }))
          }
        }
      }
    }

    return mediaPromises
  }

  const handleItemRemoval = (index) => {
    let items = checklistItems
    items.splice(index, 1);
    setChecklistItems([ ...items])
  }

  const handleNewChecklistItemSubmit = (data) => {
    toggleModal()
    setChecklistItems([ 
      ...checklistItems,
      data
    ])
  }
  
  const handleEditChecklistItemSubmit = (data) => {
    toggleModal()

    let arr = [ ...checklistItems ]

    arr[data.index] = data.obj;
    
    setChecklistItems(arr)
  }

  const handleNewSectionItem = item => {
    setModalData(item);
    toggleModal('checklistItem')
  }

  const handleNewItemCancel = () => {
    toggleModal()
  }

  const handleReorderCards = useCallback(
    (dragIndex, hoverIndex) => {  
      let reordered = [ ...checklistItems ];
      const dragCard = checklistItems[dragIndex]
      reordered.splice(dragIndex, 1);
      reordered.splice(hoverIndex, 0, dragCard);
      setChecklistItems( [...reordered])
    }, [checklistItems]
  )

  const handleSectionNameChange = (e) => {
    setSectionName(e.target.value)
  }

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value)
  }

  const handleRequireVideoChange = (e) => {
    setRequireVideo(e.target.checked);
  }

  const getLoader = () => {

    if (saveProgress === 'complete') {
      return <Loader page status='complete' title={saveProgress} />
    } else if (saveProgress === 'error') {
      return <Loader page status='error' title={saveProgress} />
    } else {
      return <Loader page title={saveProgress} />
    }
  }

  const handleItemEditClick = index => {
    const item = checklistItems[index]
    setModalData({index: index, obj: item});
    toggleModal('editChecklistItem')
  }
  
  useEffect( () => {
    if (match && match.params.sectionID) {
      if ( editType === 'EDIT' ) {
        setCurrentChecklist(match.params.sectionID)
      } 

      APIClient(`checklists/sections/${match.params.sectionID}`).then( e => {  
        setDisplayName(e.data.display_name)
        setSectionName(e.data.admin_name)
        setRequireVideo(e.data.video_recording)
        setChecklistItems(e.data.items)
        setLoading(false)
      }).catch( e => {
       // error
      })
    }
  }, [match, editType])

  return (
    <>
      { saveProgress && getLoader() }
      { loading && (
        <Loader page />
      )}
      <SectionHeader 
        header={pageTitle}
        description={pageDescription} 
        withButton="Save" 
        onButtonClick={ handleSaveSectionClick } 
      />  
      <DndProvider backend={HTML5Backend}>
        <div className='content-sidebar'>
          <div className='content-sidebar--content'>
            <div className={styles.sectionTitleSection}>
              <div className={styles.sectionHeader}>
                <h3>Checklist section details:</h3>
              </div>
              <div className={styles.sectionTitleInput}>
                <Input label="Display Title" name="DisplayTitle" value={displayName} onChange={handleDisplayNameChange} />
              </div>
              <div className={styles.displayTitleInput}>
                <Input label="Admin Title"  name="sectionTitle" value={sectionName} onChange={handleSectionNameChange} />
              </div>
              <div className={styles.sectionHeader}>
                <h3>Require video upload with this checklist?</h3>
              </div>
              <Checkbox className={styles.requireVideoInput} name="video" label="Require Video recording" value={ requireVideo } onChange={handleRequireVideoChange} />
            </div>  
            <div className={styles.sectionTitleSection}>
              <h3>Reusable Block Items:</h3>
              <p>Create a reusable block below by dragging items from the checklist item types on the right hand side into the dropzone below.</p>
              <ChecklistDropzone sectionItems={checklistItems} onReorderCards={handleReorderCards} addItemToSection={ handleNewSectionItem } onEditCard={handleItemEditClick} onRemove={handleItemRemoval} type="section" />
            </div>
          </div>
          <div className='content-sidebar--sidebar'>
            <CardPool type="item" available={['checklist_item', 'prompt', 'photo']} />
          </div>
        </div>
      </DndProvider>
      <FormModal 
        title={(`Edit `) + (`${modalData.obj ? modalData.obj.type : ''} item`).toLowerCase().replace('_item', ' ')}
        isActive={itemModalOpen === 'editChecklistItem'}
        handleClose={ handleNewItemCancel }
      >
        <EditChecklistItemModal checklistItemProps={modalData} onSubmit={ handleEditChecklistItemSubmit } />
      </FormModal>

      <FormModal 
        title={(`Add new `) + (`${modalData.type} item`).toLowerCase().replace('_item', ' ')}
        isActive={itemModalOpen === 'checklistItem'}
        handleClose={ handleNewItemCancel }
      >
        <NewChecklistItemModal checklistItemProps={modalData} onSubmit={ handleNewChecklistItemSubmit } />
      </FormModal>
    </>
  )
}

export default CreateChecklistSectionLayout
