export default function validate(values) {
  let errors = {};

  if (!values.name) {
    errors.name = 'A product name is required';
  } 
  
  if (!values.sku) {
    errors.sku = 'A product SKU is required';
  } 

  return errors;
};