import React from 'react'
import ChecklistPromptModal from './variants/ChecklistPromptModal/ChecklistPromptModal';
import ChecklistPhotoModal from './variants/ChecklistPhotoModal/ChecklistPhotoModal';
import ChecklistChecklistModal from './variants/ChecklistChecklistModal/ChecklistChecklistModal';

const NewChecklistItemModal = ({checklistItemProps, onSubmit}) => {  
  const onCheckoutItemSubmit = (values) => {
    onSubmit({
      type: checklistItemProps.type,
      ...values
    })
  }
    
  const getChecklistModalContents = () => {
    switch (checklistItemProps.type) {
        case 'prompt': 
          return <ChecklistPromptModal onSubmit={onCheckoutItemSubmit} />
        case 'photo': 
          return <ChecklistPhotoModal onSubmit={onCheckoutItemSubmit} />
        case 'checklist_item': 
          return <ChecklistChecklistModal onSubmit={onCheckoutItemSubmit} />
        default:
          break;
    }
  }

  return getChecklistModalContents()
}

export default NewChecklistItemModal