import React from 'react'
import Loader from '../Loader/Loader'
import ChecklistSectionCard from '../ChecklistSectionCard/ChecklistSectionCard'
import { cardPool, cardPoolHeader, cardPoolBody, placeholderCard, addNewButton } from './CardPool.module.scss'
import ChecklistItemCard from '../../components/ChecklistItemCard/ChecklistItemCard'
import Button from '../Button/Button'
import AddIcon from '@material-ui/icons/Add';

const CardPool = ({type, available}) => {
  let poolTitle, poolDescription

  switch (type) {
    case 'section':
      poolTitle = 'Reusable Blocks:'
      poolDescription = 'Select from the following resuable blocks to create a Quality Assurance test.'
      break;
    case 'item':
      poolTitle = 'Test Items:'
      poolDescription = 'Select from the following test items to create a resuable block'
      break;
    default:
      break;
  }

  const getCards = () => {
    let cards = []

    if (!available) {
      return (
        <>
          <div className={placeholderCard}>
            <p><Loader inline /></p>
            <Loader inline />
          </div>
          <div className={placeholderCard}>
            <p><Loader inline /></p>
            <Loader inline />
          </div>
          <div className={placeholderCard}>
            <p><Loader inline /></p>
            <Loader inline />
          </div>
        </>
      )
    }

    if( type === 'section') {
      available.map( (item, index) => {
        cards.push(<ChecklistSectionCard id={item.id} key={index} index={index} adminTitle={item.admin_name} title={item.display_name} items={item.items} />)
        return true
      })
    } else if( type === 'item') {
      available.map( (item, index) => {
        cards.push(<ChecklistItemCard key={index} type={item} />)
        return true
      })
    }

    return cards
  }

  return (
    <div className={cardPool}>
      <div className={cardPoolHeader}>
        <h3>{poolTitle}</h3>
        <p>{poolDescription}</p>
        {type === 'section' && 
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={addNewButton}
            startIcon={<AddIcon />}
            href="/checklists/section/new"
            target="_blank"
          >
            Add new
          </Button>
        } 
      </div>
      <div className={cardPoolBody}>
        { getCards() }
      </div>
    </div>
  )
}

export default CardPool