import React, { useState, useRef, useEffect } from 'react'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ImageUpload.module.scss'

const ImageUpload = ({className, removable=false, defaultImg=false, fullWidth=false, index=null, onUpdate=null, onDelete=null, array=null, name=null}) => {

  const [imagePreview, setImagePreview] = useState(null)
  const ref = useRef(null)

  useEffect( () => {
    if ( defaultImg ) {
      if (typeof defaultImg.name == 'string') {
        handleImagePreview(defaultImg)
      } else {
        setImagePreview(defaultImg.src)
      }
    }
  }, [defaultImg])

  const handleImagePreview = file => {
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = (event) => {
      setImagePreview(reader.result)
    }
  }

  const handleImageAdd = (e) => {
    let file = false;
    if ( e.dataTransfer ) {
      file = e.dataTransfer.files[0]
    } else {
      file = e.target.files[0]
    }
    if( !file ) {
      return;
    }
    
    handleImagePreview(file)

    if ( onUpdate ) {
      onUpdate(e)
    }
  }

  const handleClick = (e) => {
    if (removable && onDelete) {
      onDelete(index)
    }
  }

  return (
    <div className={[styles.uploadWrap, fullWidth && styles.fullWidth].join(' ')}>

    <label className={[styles.uploadLabel, imagePreview && styles.previewLabel, removable && styles.unchangable, className].join(' ')} onDrop={handleImageAdd}>
      { imagePreview ? (
        <img src={imagePreview} alt="TruCorp" />
        ) : (
          <div className={styles.uploadContent}>
            <PhotoCamera className={styles.uploadIcon} />
            <span className={styles.uploadText}>Add Image</span>
          </div>
      )
    }

    { array ? (
      <input type="file" arrayid={array}  name={name} accept="image/*" ref={ref} style={{ display: "none" }} onChange={handleImageAdd} />
      ) : (
      <input type="file" accept="image/*" name={name} ref={ref} style={{ display: "none" }} onChange={handleImageAdd} />
    )}

      
    </label>
    {removable && (
        <div className={styles.removal} onClick={handleClick} >
          <DeleteIcon />
        </div>
      )}
    </div>
  )
}

export default ImageUpload