import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';
import { checkboxStyle, checkboxLabelStyle } from './Checkbox.module.scss'

const Checkbox = ({className, name, label, value, onChange, defaultChecked = false}) => {
  return (
    <FormControlLabel
      className={[checkboxLabelStyle, className].join(' ')}
      control={
        <MUICheckbox
          className={checkboxStyle}
          checked={ value || false}
          onChange={onChange}
          name={name}
          value={name}
          inputProps={{
            'aria-label': label,
          }}
          color="primary"
        />
      }
      label={label}
    />
  )
}

export default Checkbox