export default function validate(values) {
  let errors = {};

  if (!values.username) {
    errors.username = 'Username is required';
  }
//    else if (!/\S+@\S+\.\S+/.test(values.username)) {
//     errors.username = 'Email address is invalid';
//   }

  if (!values.name) {
    errors.name = 'A name is required';
  } 
  
  if (!values.job_title) {
    errors.job_title = 'A job title is required';
  } 

  return errors;
};