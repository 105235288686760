
import React, { useState, useEffect } from 'react';
import APIClient from '../../utils/api-client'
import StatsCard from '../../components/StatsCard/StatsCard'
import { withRouter } from 'react-router-dom'
import styles from './StatsLayout.module.scss'

const StatsLayout = withRouter(() => {

  const [qasCompleted, setQasCompleted] = useState(null);
  const [topProducts, setTopProducts] = useState(null);

  const [retestsCompleted, setRetestsCompleted] = useState(null);
  const [topRetestProducts, setTopRetestProducts] = useState(null);

  useEffect( () => {
    getQAData();
    getRetestsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getQAData = () => {
    APIClient('stats/qas', [], 'GET').then( e => {
      setQasCompleted(e.data.qas_completed);
      setTopProducts(e.data.top_products);
    }).catch( e => {
      console.error('Error fetching data:', e);
    })
  }

  const getRetestsData = () => {
    APIClient('stats/retests', [], 'GET').then( e => {
      setRetestsCompleted(e.data.retests_completed);
      setTopRetestProducts(e.data.top_retested_products);
    }).catch( e => {
      console.error('Error fetching data:', e);
    })
  }

  return (
    <>
      <h1>QA's Performed</h1>
      <div className={styles.statsRow}>
        <StatsCard number={qasCompleted ? qasCompleted.week : '-'}  numberTitle="QA's" durationTitle="Last 7 days" topProducts={topProducts ? topProducts.week : null} />
        <StatsCard number={qasCompleted ? qasCompleted.month : '-'}  numberTitle="QA's" durationTitle="Last 30 days" topProducts={topProducts ? topProducts.month : null} />
        <StatsCard number={qasCompleted ? qasCompleted.year : '-'}  numberTitle="QA's" durationTitle="Last 365 days" topProducts={topProducts ? topProducts.year : null} />
      </div>
      <h1>Retests Performed</h1>
      <div className={styles.statsRow}>
        <StatsCard number={retestsCompleted ? retestsCompleted.week : '-'} numberTitle="Retests" durationTitle="Last 7 days" topProducts={topRetestProducts ? topRetestProducts.week : null} />
        <StatsCard number={retestsCompleted ? retestsCompleted.month : '-'}  numberTitle="Retests" durationTitle="Last 30 days" topProducts={topRetestProducts ? topRetestProducts.month : null} />
        <StatsCard number={retestsCompleted ? retestsCompleted.year : '-'} numberTitle="Retests"  durationTitle="Last 365 days" topProducts={topRetestProducts ? topRetestProducts.year : null} />
      </div>
    </>
  );
})

export default StatsLayout;