export default function validate(values) {
  let errors = {};

  if (!values.username) {
    errors.username = 'Username is required';
  }
//    else if (!/\S+@\S+\.\S+/.test(values.username)) {
//     errors.username = 'Email address is invalid';
//   }

  if (!values.name) {
    errors.name = 'A name is required';
  } 
  
  if (!values.job_title) {
    errors.job_title = 'A job title is required';
  } 
  
  if (!values.password) {
    errors.password = 'Please enter a password';
  } else if (values.password.length < 8 ) {
    errors.password = 'Password must be at least 8 characters'
  }


  return errors;
};