import React, { useRef } from 'react'
import { Warning, CheckBoxOutlined, PhotoCamera, DragIndicator } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd'
import styles from './ChecklistItemCard.module.scss'

const ChecklistItemCard = ({ isDragging, type, placed=false, index=9999, maintext, onDelete, onEdit, moveCard}) => {
  
  const ref = useRef(null)

  const titles = {
    prompt: 'Prompt',
    checklist_item: 'Checklist Item',
    photo: 'Photo',
  }
  
  const [, drop] = useDrop({
    accept: ['checklist_item', 'photo', 'prompt'],
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      if(!item.variant) {
        return
      }
      
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ opacity }, drag] = useDrag({
    item: { type: type, variant: placed, index: index},
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        // when dropped
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  drag(drop(ref))

  const getIcon = () => {
    switch (type) {
      case 'prompt':
        return <Warning className={styles.largeIcon} />
      case 'photo':
        return <PhotoCamera className={styles.largeIcon} />
      case 'checklist_item':
        return <CheckBoxOutlined className={styles.largeIcon} />
      default:
        return <Warning className={styles.largeIcon} />
    }
  }

  return (
    <div ref={ref} style={{opacity}} className={styles.checklistCard}>
      {placed && (
        <div className={styles.closeBtn}>
          <span onClick={onEdit}><EditIcon /></span>
          <span onClick={onDelete}><CloseIcon /></span>
        </div>
      )}
      { getIcon() }
      <div className={styles.checklistCardContent}>
        <h4>{ titles[type] }</h4>
        { maintext && (
            <p>{maintext}</p>
        )}
      </div>
      <DragIndicator className={styles.dragIcon} htmlColor="#efefef"/>
      <div className={styles.checklistCardActions}>
        <span className={styles.menuBars}></span>
      </div>
    </div>
  )
}

ChecklistItemCard.propTypes = {
  type: PropTypes.oneOf(['checklist_item', 'prompt', 'photo']),
}

export default ChecklistItemCard
