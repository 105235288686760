import APIClient from './api-client'

const LSKey = 'TCQAUser'

const handleLoginResponse = (resp) => {
  if ( resp.data.token ) {
    window.localStorage.setItem(LSKey, resp.data.token);    
  }
}

const login = ({username, password}) => {
  return APIClient('auth/login', {
    "username": username,
    "password": password
  }).then(handleLoginResponse)
}

const register = ({username, password}) => {
  return APIClient('register', {body: {username, password}}).then(
    handleLoginResponse,
  )
}

const logout = () => {
  window.localStorage.removeItem(LSKey)
  return Promise.resolve()
}

const getToken = () => {
  return window.localStorage.getItem(LSKey)
}

export {login, register, logout, getToken}