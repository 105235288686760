import React from 'react'
import { LabelledWrap, LabelledContent, underlined, smallText } from './LabelledDetail.module.scss'
import classnames from 'classnames'

const LabelledDetail = ({label, children, small=false, className=null, underline=false}) => {

  return (
    <div className={classnames(className, LabelledWrap)}>
     <h3 className={underline ? underlined : null}>{ label }</h3>
     <div className={classnames(LabelledContent, small && smallText)}>
      { children }
     </div>
    </div>
  )
}

export default LabelledDetail